import { loadStripe } from '@stripe/stripe-js';

const stripePublicKey = import.meta.env.VITE_STRIPE_PUBLIC_KEY;
if (!stripePublicKey) {
  throw new Error('Missing Stripe public key');
}

export const stripePromise = loadStripe(stripePublicKey);

export const STRIPE_PRICE_IDS = {
  starter: 'price_1QTngbABVF5YHuUovmFVo71s',
  professional: 'price_1QTngMABVF5YHuUo7mmHC4k4',
  enterprise: 'price_1QTng5ABVF5YHuUoITy0wBtb'
} as const;

export type StripePriceId = typeof STRIPE_PRICE_IDS[keyof typeof STRIPE_PRICE_IDS];