import { PlanId, PRICING_PLANS } from '../../config/pricing';
import { PlanFeatures } from './types';

export function getInitialPlanFeatures(planId: PlanId): PlanFeatures {
  const plan = PRICING_PLANS[planId];
  
  return {
    apiCalls: {
      limit: plan.apiCalls.included,
      used: 0,
      remaining: plan.apiCalls.included
    },
    analytics: {
      type: plan.analytics.type,
      features: plan.analytics.features
    },
    support: {
      type: plan.support.type,
      responseTime: plan.support.responseTime
    },
    dataRetention: plan.dataRetention,
    customIntegrations: planId !== 'developer',
    webhooksEnabled: planId !== 'developer',
    multipleEnvironments: planId !== 'developer',
    customRateLimits: planId === 'business' || planId === 'enterprise',
    technicalAccountManager: planId === 'business' || planId === 'enterprise'
  };
}

export function isFeatureAvailable(
  features: PlanFeatures,
  featureName: keyof PlanFeatures
): boolean {
  const feature = features[featureName];
  
  if (typeof feature === 'boolean') {
    return feature;
  }
  
  if (featureName === 'apiCalls') {
    return features.apiCalls.remaining > 0;
  }
  
  return true;
}

export function hasAnalyticsFeature(
  features: PlanFeatures,
  featureName: string
): boolean {
  return features.analytics.features.includes(featureName);
}

export function canUseCustomIntegration(features: PlanFeatures): boolean {
  return features.customIntegrations;
}

export function canUseWebhooks(features: PlanFeatures): boolean {
  return features.webhooksEnabled;
}

export function getDataRetentionDays(features: PlanFeatures): number {
  return features.dataRetention;
}