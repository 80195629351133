import { db } from '../lib/firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { PlanFeatures, FeatureUpdate } from '../lib/features/types';
import { getInitialPlanFeatures } from '../lib/features/planFeatures';
import { PlanId } from '../config/pricing';

export async function initializeUserFeatures(userId: string, planId: PlanId): Promise<void> {
  const features = getInitialPlanFeatures(planId);
  const userFeaturesRef = doc(db, 'userFeatures', userId);
  
  await setDoc(userFeaturesRef, {
    features,
    planId,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  });
}

export async function getUserFeatures(userId: string): Promise<PlanFeatures | null> {
  const userFeaturesRef = doc(db, 'userFeatures', userId);
  const docSnap = await getDoc(userFeaturesRef);
  
  if (!docSnap.exists()) {
    return null;
  }
  
  return docSnap.data().features as PlanFeatures;
}

export async function updateFeature(
  userId: string,
  update: FeatureUpdate
): Promise<void> {
  const userFeaturesRef = doc(db, 'userFeatures', userId);
  const docSnap = await getDoc(userFeaturesRef);
  
  if (!docSnap.exists()) {
    throw new Error('User features not found');
  }
  
  const currentFeatures = docSnap.data().features as PlanFeatures;
  let updatedValue;
  
  if (update.type === 'increment' && typeof update.value === 'number') {
    const currentValue = (currentFeatures[update.feature] as any).used;
    updatedValue = currentValue + update.value;
  } else {
    updatedValue = update.value;
  }
  
  await updateDoc(userFeaturesRef, {
    [`features.${update.feature}`]: updatedValue,
    updatedAt: new Date().toISOString()
  });
}

export async function trackApiCall(userId: string): Promise<void> {
  const userFeaturesRef = doc(db, 'userFeatures', userId);
  const docSnap = await getDoc(userFeaturesRef);
  
  if (!docSnap.exists()) {
    throw new Error('User features not found');
  }
  
  const features = docSnap.data().features as PlanFeatures;
  const newUsed = features.apiCalls.used + 1;
  const newRemaining = features.apiCalls.limit - newUsed;
  
  await updateDoc(userFeaturesRef, {
    'features.apiCalls.used': newUsed,
    'features.apiCalls.remaining': newRemaining,
    updatedAt: new Date().toISOString()
  });
}

export async function resetApiCallCount(userId: string): Promise<void> {
  const userFeaturesRef = doc(db, 'userFeatures', userId);
  const docSnap = await getDoc(userFeaturesRef);
  
  if (!docSnap.exists()) {
    throw new Error('User features not found');
  }
  
  const features = docSnap.data().features as PlanFeatures;
  
  await updateDoc(userFeaturesRef, {
    'features.apiCalls.used': 0,
    'features.apiCalls.remaining': features.apiCalls.limit,
    updatedAt: new Date().toISOString()
  });
}