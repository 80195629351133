import { ShoppingCart, Briefcase, Building2, Truck, CreditCard } from 'lucide-react';

export const solutionDetails = {
  ecommerce: {
    id: 'ecommerce',
    title: 'E-commerce Solutions',
    icon: <ShoppingCart className="w-8 h-8 text-purple-400" />,
    description: 'Comprehensive financial infrastructure for modern e-commerce platforms. Accept payments globally, offer flexible payment options, and boost customer loyalty.',
    features: [
      'Global payment processing with support for 135+ currencies',
      'Buy Now, Pay Later integration with major providers',
      'Advanced fraud detection and prevention',
      'Customer loyalty program management',
      'Automated reconciliation and reporting',
      'Real-time inventory financing'
    ],
    integrationSteps: [
      'Sign up for a Valiant API account and get your API keys',
      'Install our SDK using npm or yarn',
      'Configure payment methods and currencies',
      'Implement checkout flow using our pre-built components',
      'Test transactions in sandbox environment',
      'Go live with production credentials'
    ],
    codeExample: `// Initialize Valiant E-commerce SDK
const valiant = new ValiantAPI('your-api-key');

// Create a payment session
const session = await valiant.ecommerce.createCheckout({
  amount: 1999,
  currency: 'USD',
  payment_methods: ['card', 'apple_pay', 'klarna'],
  success_url: 'https://your-store.com/success',
  cancel_url: 'https://your-store.com/cart'
});

// Handle successful payment
valiant.on('payment.success', (payment) => {
  console.log('Payment successful:', payment.id);
  // Update order status
});`
  },
  b2b: {
    id: 'b2b',
    title: 'B2B Platform Solutions',
    icon: <Briefcase className="w-8 h-8 text-purple-400" />,
    description: 'Modernize B2B payments and unlock working capital for your business customers. Streamline vendor payments and optimize cash flow.',
    features: [
      'Automated invoice processing and payments',
      'Working capital financing',
      'International wire transfers',
      'Virtual corporate cards',
      'Vendor payment optimization',
      'Real-time cash flow analytics'
    ],
    integrationSteps: [
      'Create a Valiant API account for B2B services',
      'Complete business verification process',
      'Install B2B payments SDK',
      'Set up vendor payment workflows',
      'Configure automated invoice processing',
      'Launch financing options'
    ],
    codeExample: `// Initialize B2B payments
const b2b = valiant.b2b.initialize({
  business_id: 'your-business-id',
  vendor_verification: true
});

// Process vendor payment
const payment = await b2b.processPayment({
  vendor_id: 'vendor-123',
  amount: 50000,
  currency: 'USD',
  invoice_id: 'inv-456',
  payment_method: 'ach',
  memo: 'Q1 Services'
});`
  },
  saas: {
    id: 'saas',
    title: 'SaaS Solutions',
    icon: <Building2 className="w-8 h-8 text-purple-400" />,
    description: 'Embed financial services directly into your SaaS platform. Increase revenue and improve customer retention with integrated financial tools.',
    features: [
      'Subscription billing automation',
      'Revenue sharing and split payments',
      'Marketplace payment distribution',
      'Usage-based billing',
      'Multi-currency support',
      'Automated dunning management'
    ],
    integrationSteps: [
      'Sign up for Valiant SaaS integration',
      'Configure subscription plans',
      'Implement billing webhooks',
      'Set up automated invoicing',
      'Enable revenue sharing rules',
      'Launch marketplace payments'
    ],
    codeExample: `// Initialize SaaS billing
const billing = valiant.saas.billing.initialize();

// Create a subscription
const subscription = await billing.createSubscription({
  customer_id: 'cust_123',
  plan_id: 'plan_premium',
  billing_cycle: 'monthly',
  payment_method: 'card',
  metadata: {
    team_size: 10,
    features: ['analytics', 'api_access']
  }
});`
  }
};