import { z } from 'zod';

export const FREE_TRIAL_DURATION = 14;

export interface PricingTier {
  id: string;
  name: string;
  basePrice: number;
  trialDays?: number;
  features: string[];
  apiCalls: {
    included: number;
    overage: number;
  };
  support: {
    type: 'email' | 'chat' | 'dedicated';
    responseTime: string;
  };
  analytics: {
    type: 'basic' | 'advanced' | 'custom';
    features: string[];
  };
  dataRetention: number;
}

export const PRICING_PLANS: Record<string, PricingTier> = {
  developer: {
    id: 'developer',
    name: 'Developer',
    basePrice: 29,
    trialDays: FREE_TRIAL_DURATION,
    features: [
      '5,000 API calls/month',
      'Basic analytics',
      'Email support (48h response)',
      '30 days data retention',
      'Community access',
      'API documentation',
      'Sandbox testing'
    ],
    apiCalls: {
      included: 5000,
      overage: 0.001
    },
    support: {
      type: 'email',
      responseTime: '48 hours'
    },
    analytics: {
      type: 'basic',
      features: ['API usage', 'Basic reporting', 'Error tracking']
    },
    dataRetention: 30
  },
  growth: {
    id: 'growth',
    name: 'Growth',
    basePrice: 99,
    features: [
      '50,000 API calls/month',
      'Advanced analytics',
      'Chat support (24h response)',
      '90 days data retention',
      'Custom integrations',
      'Webhooks',
      'Multiple environments'
    ],
    apiCalls: {
      included: 50000,
      overage: 0.0008
    },
    support: {
      type: 'chat',
      responseTime: '24 hours'
    },
    analytics: {
      type: 'advanced',
      features: ['Custom dashboards', 'Usage insights', 'Performance metrics', 'Error analysis']
    },
    dataRetention: 90
  },
  business: {
    id: 'business',
    name: 'Business',
    basePrice: 299,
    features: [
      '200,000 API calls/month',
      'Custom analytics',
      'Priority chat support (6h response)',
      '180 days data retention',
      'Advanced security features',
      'Custom rate limits',
      'Technical account manager'
    ],
    apiCalls: {
      included: 200000,
      overage: 0.0005
    },
    support: {
      type: 'chat',
      responseTime: '6 hours'
    },
    analytics: {
      type: 'advanced',
      features: ['Custom dashboards', 'Real-time monitoring', 'Advanced reporting', 'API metrics', 'Custom alerts']
    },
    dataRetention: 180
  },
  enterprise: {
    id: 'enterprise',
    name: 'Enterprise',
    basePrice: null,
    features: [
      'Unlimited API calls',
      'Custom analytics',
      'Dedicated support team',
      'Unlimited data retention',
      'Custom SLA',
      'On-premise deployment option',
      'Dedicated infrastructure',
      'Custom compliance packages',
      'MSA flexibility'
    ],
    apiCalls: {
      included: Infinity,
      overage: 0
    },
    support: {
      type: 'dedicated',
      responseTime: '1 hour'
    },
    analytics: {
      type: 'custom',
      features: ['Custom analytics solutions', 'Dedicated BI tools', 'Custom reporting']
    },
    dataRetention: Infinity
  }
} as const;

export type PlanId = keyof typeof PRICING_PLANS;

export const STRIPE_PRICE_IDS = {
  developer: 'price_developer123',
  growth: 'price_growth456',
  business: 'price_business789',
  enterprise: 'price_enterprise012'
} as const;

export type StripePriceId = typeof STRIPE_PRICE_IDS[keyof typeof STRIPE_PRICE_IDS];