import React from 'react';
import { CreditCard, Banknote, Building2, Shield, ScrollText } from 'lucide-react';

export function Products() {
  return (
    <section id="products" className="py-20 bg-gray-900">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-white mb-4">
            Complete Financial Infrastructure
          </h2>
          <p className="text-xl text-gray-400 max-w-2xl mx-auto">
            Everything you need to embed financial services into your platform
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          <ProductCard
            icon={<CreditCard className="w-8 h-8 text-purple-400" />}
            title="Payments-as-a-Service"
            description="Accept payments globally with support for multiple currencies and payment methods"
            features={[
              "Global payment processing",
              "Multi-currency support",
              "Automated reconciliation",
              "Fraud prevention"
            ]}
          />

          <ProductCard
            icon={<Banknote className="w-8 h-8 text-purple-400" />}
            title="Lending-as-a-Service"
            description="Offer flexible financing options with AI-powered risk assessment"
            features={[
              "Buy now, pay later",
              "Working capital loans",
              "AI underwriting",
              "Risk management"
            ]}
          />

          <ProductCard
            icon={<Building2 className="w-8 h-8 text-purple-400" />}
            title="Banking-as-a-Service"
            description="Launch branded banking products for your customers"
            features={[
              "Virtual accounts",
              "Debit cards",
              "Loyalty programs",
              "Real-time transfers"
            ]}
          />

          <ProductCard
            icon={<Shield className="w-8 h-8 text-purple-400" />}
            title="Insurance-as-a-Service"
            description="Seamlessly integrate insurance products into your platform"
            features={[
              "Product insurance",
              "Travel coverage",
              "Automated claims",
              "Risk analytics"
            ]}
          />

          <ProductCard
            icon={<ScrollText className="w-8 h-8 text-purple-400" />}
            title="Compliance-as-a-Service"
            description="Stay compliant with automated regulatory tools"
            features={[
              "KYC verification",
              "AML screening",
              "GDPR compliance",
              "Audit trails"
            ]}
          />
        </div>
      </div>
    </section>
  );
}

function ProductCard({ 
  icon, 
  title, 
  description, 
  features 
}: { 
  icon: React.ReactNode; 
  title: string; 
  description: string;
  features: string[];
}) {
  return (
    <div className="bg-gray-800 rounded-xl p-8 border border-gray-700 hover:border-purple-500 transition-colors">
      <div className="mb-6">{icon}</div>
      <h3 className="text-xl font-semibold text-white mb-3">{title}</h3>
      <p className="text-gray-400 mb-6">{description}</p>
      <ul className="space-y-2">
        {features.map((feature, index) => (
          <li key={index} className="text-gray-300 flex items-center">
            <div className="w-1.5 h-1.5 bg-purple-500 rounded-full mr-2" />
            {feature}
          </li>
        ))}
      </ul>
    </div>
  );
}