import React from 'react';
import { PRICING_PLANS } from '../../config/pricing';
import { PricingPlanCard } from './PricingPlanCard';

export function PricingPlans() {
  return (
    <section id="pricing" className="py-20 bg-gray-900">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-white mb-4">
            Simple, Transparent Pricing
          </h2>
          <p className="text-xl text-gray-400 mb-2">
            Start with a base plan and only pay for what you use
          </p>
          <p className="text-purple-400">
            Try our Developer plan with a 14-day free trial
          </p>
        </div>

        {/* Updated grid to show all plans in one row */}
        <div className="flex flex-col lg:flex-row gap-8 max-w-[1400px] mx-auto">
          {(Object.keys(PRICING_PLANS) as Array<keyof typeof PRICING_PLANS>).map((planId) => (
            <div key={planId} className="flex-1">
              <PricingPlanCard planId={planId} />
            </div>
          ))}
        </div>

        <div className="mt-16 text-center">
          <p className="text-gray-400">
            All plans include our core infrastructure. Need a custom plan?{' '}
            <a href="mailto:enterprise@valiantapi.com" className="text-purple-400 hover:text-purple-300">
              Contact us
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}