import { useState, useEffect } from 'react';
import { db } from '../lib/firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { useAuth } from './useAuth';

export interface DashboardStats {
  apiCalls: {
    total: number;
    change: number;
    isPositive: boolean;
  };
  activeUsers: {
    total: number;
    change: number;
    isPositive: boolean;
  };
  revenue: {
    total: number;
    change: number;
    isPositive: boolean;
  };
}

export function useDashboardStats() {
  const { user } = useAuth();
  const [stats, setStats] = useState<DashboardStats | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!user) return;

    setLoading(true);
    setError(null);

    const unsubscribe = onSnapshot(
      doc(db, 'dashboardStats', user.uid),
      (doc) => {
        if (doc.exists()) {
          setStats(doc.data() as DashboardStats);
        }
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching dashboard stats:', err);
        setError('Failed to load dashboard statistics');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [user]);

  return { stats, loading, error };
}