import { useState, useEffect } from 'react';
import { useAuth } from './useAuth';
import { getSubscription } from '../services/subscriptionService';
import type { PlanTier } from '../config/plans';

export function useSubscription() {
  const { user } = useAuth();
  const [subscription, setSubscription] = useState<{
    planId: PlanTier;
    status: string;
    currentPeriodEnd: string;
    cancelAtPeriodEnd: boolean;
  } | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchSubscription() {
      if (!user) {
        setSubscription(null);
        setLoading(false);
        return;
      }

      try {
        const data = await getSubscription(user.uid);
        setSubscription(data);
      } catch (err) {
        setError('Failed to fetch subscription details');
        console.error('Error fetching subscription:', err);
      } finally {
        setLoading(false);
      }
    }

    fetchSubscription();
  }, [user]);

  return { subscription, loading, error };
}