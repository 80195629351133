import React from 'react';
import { useNavigate } from 'react-router-dom';
import { solutionDetails } from './solutionData';

export function Solutions() {
  const navigate = useNavigate();
  
  return (
    <section id="solutions" className="py-20 bg-gray-900">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-white mb-4">
            Industry Solutions
          </h2>
          <p className="text-xl text-gray-400 max-w-2xl mx-auto">
            Tailored financial solutions for every industry
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {Object.entries(solutionDetails).map(([key, solution]) => (
            <button
              key={key}
              onClick={() => navigate(`/solutions/${key}`)}
              className="text-left"
            >
              <div className="bg-gray-800 rounded-xl p-8 border border-gray-700 hover:border-purple-500 transition-colors">
                <div className="mb-6">{solution.icon}</div>
                <h3 className="text-xl font-semibold text-white mb-3">{solution.title}</h3>
                <p className="text-gray-400 mb-6">{solution.description}</p>
                <ul className="space-y-2">
                  {solution.features.slice(0, 4).map((feature, index) => (
                    <li key={index} className="text-gray-300 flex items-center">
                      <div className="w-1.5 h-1.5 bg-purple-500 rounded-full mr-2" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </button>
          ))}
        </div>
      </div>
    </section>
  );
}