import React from 'react';
import { Tooltip } from 'react-tooltip';
import { Info } from 'lucide-react';
import { WorkflowBlock } from '../types';
import { getComponentConfig } from '../config/components';

interface ConfigPanelProps {
  block: WorkflowBlock;
  onUpdateBlock: (config: any) => void;
}

export function ConfigPanel({ block, onUpdateBlock }: ConfigPanelProps) {
  const config = getComponentConfig(block.type);

  if (!config) {
    return (
      <div className="bg-gray-800 rounded-xl p-6">
        <p className="text-red-400">Configuration not found for component type: {block.type}</p>
      </div>
    );
  }

  const handleApiKeyChange = (value: string) => {
    onUpdateBlock({
      ...block.config,
      apiKey: value
    });
  };

  const handleParameterChange = (name: string, value: any) => {
    onUpdateBlock({
      ...block.config,
      parameters: {
        ...block.config.parameters,
        [name]: value
      }
    });
  };

  const handleObjectParameterChange = (paramName: string, fieldName: string, value: any) => {
    onUpdateBlock({
      ...block.config,
      parameters: {
        ...block.config.parameters,
        [paramName]: {
          ...(block.config.parameters?.[paramName] || {}),
          [fieldName]: value
        }
      }
    });
  };

  const renderParameterInput = (param: any) => {
    const currentValue = block.config.parameters?.[param.name];
    const tooltipId = `param-tooltip-${param.name}`;

    const inputWrapper = (input: React.ReactNode) => (
      <div className="relative">
        {input}
        <Info 
          className="absolute right-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400 cursor-help"
          data-tooltip-id={tooltipId}
        />
        <Tooltip 
          id={tooltipId}
          content={param.description}
          className="bg-gray-900 text-white p-2 text-sm rounded-lg max-w-xs"
        />
      </div>
    );

    switch (param.type) {
      case 'object':
        return (
          <div className="space-y-3 mt-2">
            {Object.entries(param.properties || {}).map(([fieldName, fieldType]) => (
              <div key={fieldName}>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  {fieldName}
                  <span className="text-xs text-gray-500 ml-1">({fieldType})</span>
                </label>
                {Array.isArray(fieldType) ? (
                  inputWrapper(
                    <select
                      value={currentValue?.[fieldName] || ''}
                      onChange={(e) => handleObjectParameterChange(param.name, fieldName, e.target.value)}
                      className="w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 text-white text-sm pr-10"
                    >
                      <option value="">Select {fieldName}</option>
                      {fieldType.map((option) => (
                        <option key={option} value={option}>{option}</option>
                      ))}
                    </select>
                  )
                ) : (
                  inputWrapper(
                    <input
                      type={fieldType === 'number' ? 'number' : 'text'}
                      value={currentValue?.[fieldName] || ''}
                      onChange={(e) => handleObjectParameterChange(param.name, fieldName, e.target.value)}
                      className="w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 text-white text-sm pr-10"
                      placeholder={`Enter ${fieldName}`}
                    />
                  )
                )}
              </div>
            ))}
          </div>
        );

      case 'boolean':
        return (
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={currentValue || false}
              onChange={(e) => handleParameterChange(param.name, e.target.checked)}
              className="form-checkbox h-4 w-4 text-purple-500 rounded border-gray-600 bg-gray-700"
            />
            <span className="text-gray-300 text-sm">{param.description}</span>
          </label>
        );

      case 'number':
        return inputWrapper(
          <input
            type="number"
            value={currentValue || ''}
            onChange={(e) => handleParameterChange(param.name, parseFloat(e.target.value))}
            className="w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 text-white pr-10"
            placeholder={param.description}
          />
        );

      default:
        return inputWrapper(
          <input
            type="text"
            value={currentValue || ''}
            onChange={(e) => handleParameterChange(param.name, e.target.value)}
            className="w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 text-white pr-10"
            placeholder={param.description}
          />
        );
    }
  };

  return (
    <div className="bg-gray-800 rounded-xl p-6">
      <h2 className="text-lg font-semibold text-white mb-4">Configure {config.name}</h2>
      
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-400 mb-2">
            API Key
            <span className="text-red-400 ml-1">*</span>
          </label>
          <div className="relative">
            <input
              type="password"
              value={block.config.apiKey || ''}
              onChange={(e) => handleApiKeyChange(e.target.value)}
              className="w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 text-white pr-10"
              placeholder="Enter your API key"
            />
            <Info 
              className="absolute right-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400 cursor-help"
              data-tooltip-id="api-key-tooltip"
            />
            <Tooltip 
              id="api-key-tooltip"
              content="Your API key from the dashboard. Use test keys for development and live keys for production."
              className="bg-gray-900 text-white p-2 text-sm rounded-lg max-w-xs"
            />
          </div>
          <p className="text-xs text-gray-500 mt-1">
            Use test keys for development and live keys for production
          </p>
        </div>

        <div className="border-t border-gray-700 pt-4">
          <h3 className="text-sm font-medium text-gray-400 mb-3">Parameters</h3>
          <div className="space-y-4">
            {config.parameters?.map((param) => (
              <div key={param.name}>
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  {param.name}
                  {param.required && <span className="text-red-400 ml-1">*</span>}
                  <span className="block text-xs text-gray-500">{param.description}</span>
                </label>
                {renderParameterInput(param)}
              </div>
            ))}
          </div>
        </div>

        {block.config.apiKey && (
          <div className="mt-4 p-3 bg-green-500/10 border border-green-500/20 rounded-lg">
            <p className="text-sm text-green-400">
              ✓ Component configured successfully
            </p>
          </div>
        )}
      </div>
    </div>
  );
}