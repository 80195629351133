import { v4 as uuidv4 } from 'uuid';

export function generateSecureRandomString(length: number): string {
  const array = new Uint8Array(length);
  const values = crypto.getRandomValues(array);
  return Array.from(values)
    .map(b => b.toString(16).padStart(2, '0'))
    .join('');
}

export function hashApiKey(apiKey: string): string {
  const encoder = new TextEncoder();
  const data = encoder.encode(apiKey);
  return Array.from(data)
    .map(b => b.toString(16).padStart(2, '0'))
    .join('');
}

export function generateKeyFingerprint(apiKey: string): string {
  const prefix = apiKey.split('_')[0];
  const timestamp = Date.now().toString(36);
  const random = generateSecureRandomString(8);
  return `${prefix}_fp_${timestamp}${random}`;
}