import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppContent } from './components/AppContent';
import { AuthProvider } from './components/auth/AuthContext';
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from './lib/stripe';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Elements stripe={stripePromise}>
          <AppContent />
        </Elements>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;