import React from 'react';
import { DollarSign } from 'lucide-react';
import { useBilling } from '../../../hooks/useBilling';

export function BillingHistory() {
  const { billingInfo, loading } = useBilling();

  if (loading) {
    return (
      <div className="bg-gray-800 rounded-xl p-6">
        <h2 className="text-xl font-semibold text-white mb-4">Billing History</h2>
        <div className="animate-pulse space-y-4">
          {[1, 2, 3].map((i) => (
            <div key={i} className="flex items-center justify-between">
              <div className="h-4 bg-gray-700 rounded w-32"></div>
              <div className="h-4 bg-gray-700 rounded w-24"></div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (!billingInfo) {
    return (
      <div className="bg-gray-800 rounded-xl p-6">
        <h2 className="text-xl font-semibold text-white mb-4">Billing History</h2>
        <p className="text-gray-400">No billing history available</p>
      </div>
    );
  }

  // Get last 3 months of billing
  const today = new Date();
  const transactions = Array.from({ length: 3 }, (_, i) => {
    const date = new Date(today.getFullYear(), today.getMonth() - i);
    return {
      date: date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' }),
      amount: billingInfo.amount
    };
  });

  return (
    <div className="bg-gray-800 rounded-xl p-6">
      <h2 className="text-xl font-semibold text-white mb-4">Billing History</h2>
      <div className="space-y-4">
        {transactions.map((transaction, index) => (
          <div key={index} className="flex items-center justify-between text-gray-300">
            <div className="flex items-center space-x-3">
              <DollarSign className="w-5 h-5 text-gray-400" />
              <span>{transaction.date}</span>
            </div>
            <span>${transaction.amount.toFixed(2)}</span>
          </div>
        ))}
      </div>
    </div>
  );
}