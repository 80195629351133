import React from 'react';
import { Shield, DollarSign, Code2, ArrowRight, CreditCard, Building2, AlertTriangle } from 'lucide-react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

export function ApiDocumentation() {
  return (
    <div className="min-h-screen bg-gray-900 pt-24">
      <div className="container mx-auto px-6">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold text-white mb-6">
            API Documentation
          </h1>

          {/* Important Notice */}
          <section className="bg-yellow-500/10 border border-yellow-500/50 rounded-xl p-8 mb-8">
            <div className="flex items-start">
              <AlertTriangle className="w-6 h-6 text-yellow-400 mt-1 mr-3 flex-shrink-0" />
              <div>
                <h2 className="text-xl font-semibold text-yellow-400 mb-4">Important Notice</h2>
                <div className="space-y-6 text-gray-300">
                  <p>
                    Valiant API provides infrastructure tools for building financial applications. We do not:
                  </p>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Offer or process financial services directly</li>
                    <li>Handle or store sensitive financial data</li>
                    <li>Provide financial licenses or regulatory compliance</li>
                    <li>Make lending, insurance, or banking decisions</li>
                  </ul>
                  <p className="text-yellow-400 font-medium">
                    Users are responsible for:
                  </p>
                  <ul className="list-disc pl-6 space-y-2">
                    <li>Obtaining necessary financial licenses and regulatory approvals</li>
                    <li>Ensuring compliance with financial regulations (KYC, AML, etc.)</li>
                    <li>Managing relationships with financial service providers</li>
                    <li>Securing and encrypting sensitive data</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          {/* API Key Usage */}
          <section className="bg-gray-800 rounded-xl p-8 mb-8">
            <div className="flex items-center mb-6">
              <Shield className="w-8 h-8 text-purple-400 mr-4" />
              <h2 className="text-2xl font-semibold text-white">API Key Usage</h2>
            </div>
            <p className="text-gray-300 mb-4">
              Initialize the client with your API key from the dashboard. Use test keys for development and live keys for production.
            </p>
            <SyntaxHighlighter
              language="javascript"
              style={vscDarkPlus}
              className="rounded-lg mb-4"
            >
{`// Initialize with live key for production
const valiant = new ValiantAPI('vlt_live_your_api_key');

// Or initialize with test key for development/testing
const testClient = new ValiantAPI('vlt_test_your_api_key');`}
            </SyntaxHighlighter>
            <div className="bg-yellow-500/10 border border-yellow-500/50 rounded-lg p-4 text-yellow-400 mb-4">
              <p>Important: Never expose your live API key in client-side code. Always store API keys securely in environment variables.</p>
            </div>
            <div className="space-y-4">
              <h3 className="text-lg font-semibold text-white">Key Points:</h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-300">
                <li>Use <code className="text-purple-400">vlt_live_</code> prefixed keys for production</li>
                <li>Use <code className="text-purple-400">vlt_test_</code> prefixed keys for testing/development</li>
                <li>The API key is set once when initializing the client</li>
                <li>All subsequent API calls will automatically use that key</li>
                <li>API usage is automatically tracked and limited based on your plan</li>
              </ul>
            </div>
          </section>

          {/* Payment Infrastructure */}
          <section className="bg-gray-800 rounded-xl p-8 mb-8">
            <div className="flex items-center mb-6">
              <CreditCard className="w-8 h-8 text-purple-400 mr-4" />
              <h2 className="text-2xl font-semibold text-white">Payment Infrastructure</h2>
            </div>
            <p className="text-gray-300 mb-4">
              Infrastructure for integrating your payment providers. You maintain full control over payment processing while we provide the integration layer.
            </p>
            <div className="bg-gray-900/50 rounded-lg p-4 mb-6">
              <h3 className="text-white font-medium mb-2">Provider Requirements</h3>
              <ul className="text-gray-400 space-y-1">
                <li>• Valid payment processor account (e.g., Stripe, PayPal)</li>
                <li>• Payment processor API credentials</li>
                <li>• PCI compliance for card processing</li>
              </ul>
            </div>
            <SyntaxHighlighter
              language="javascript"
              style={vscDarkPlus}
              className="rounded-lg"
            >
{`// Process a payment
const payment = await valiant.payments.create({
  amount: 1000,
  currency: 'USD',
  paymentMethod: {
    type: 'card',
    token: 'payment_token_from_provider',
    provider: 'stripe'
  },
  description: 'Order #123',
  metadata: {
    orderId: '123',
    customerId: 'cust_456'
  }
});`}
            </SyntaxHighlighter>
          </section>

          {/* Banking Infrastructure */}
          <section className="bg-gray-800 rounded-xl p-8 mb-8">
            <div className="flex items-center mb-6">
              <Building2 className="w-8 h-8 text-purple-400 mr-4" />
              <h2 className="text-2xl font-semibold text-white">Banking Infrastructure</h2>
            </div>
            <p className="text-gray-300 mb-4">
              Infrastructure for integrating banking services. You maintain relationships with banking providers while we provide the integration tools.
            </p>
            <div className="bg-gray-900/50 rounded-lg p-4 mb-6">
              <h3 className="text-white font-medium mb-2">Provider Requirements</h3>
              <ul className="text-gray-400 space-y-1">
                <li>• Banking provider partnership</li>
                <li>• Banking license or partnership with licensed institution</li>
                <li>• Compliance with banking regulations</li>
              </ul>
            </div>
            <SyntaxHighlighter
              language="javascript"
              style={vscDarkPlus}
              className="rounded-lg"
            >
{`// Create a virtual account
const account = await valiant.banking.createAccount({
  type: 'checking',
  currency: 'USD',
  businessName: 'Acme Inc',
  settings: {
    interestEnabled: true,
    overdraftEnabled: false,
    transactionWebhookUrl: 'https://your-domain.com/webhooks/transactions'
  }
});`}
            </SyntaxHighlighter>
          </section>

          {/* Lending Infrastructure */}
          <section className="bg-gray-800 rounded-xl p-8 mb-8">
            <div className="flex items-center mb-6">
              <DollarSign className="w-8 h-8 text-purple-400 mr-4" />
              <h2 className="text-2xl font-semibold text-white">Lending Infrastructure</h2>
            </div>
            <p className="text-gray-300 mb-4">
              Infrastructure for building lending products. You maintain control over lending decisions and risk management while we provide integration tools.
            </p>
            <div className="bg-gray-900/50 rounded-lg p-4 mb-6">
              <h3 className="text-white font-medium mb-2">Provider Requirements</h3>
              <ul className="text-gray-400 space-y-1">
                <li>• Lending license or partnership with licensed lender</li>
                <li>• Risk assessment system</li>
                <li>• Compliance with lending regulations</li>
              </ul>
            </div>
            <SyntaxHighlighter
              language="javascript"
              style={vscDarkPlus}
              className="rounded-lg"
            >
{`// Create a lending product
const product = await valiant.lending.createProduct({
  name: 'Working Capital Loan',
  type: 'working_capital',
  termRange: { min: 3, max: 12 },
  amountRange: {
    min: 10000,
    max: 100000,
    currency: 'USD'
  }
});`}
            </SyntaxHighlighter>
          </section>

          {/* Insurance Infrastructure */}
          <section className="bg-gray-800 rounded-xl p-8 mb-8">
            <div className="flex items-center mb-6">
              <Shield className="w-8 h-8 text-purple-400 mr-4" />
              <h2 className="text-2xl font-semibold text-white">Insurance Infrastructure</h2>
            </div>
            <p className="text-gray-300 mb-4">
              Infrastructure for building insurance products. You maintain relationships with insurance providers while we provide integration tools.
            </p>
            <div className="bg-gray-900/50 rounded-lg p-4 mb-6">
              <h3 className="text-white font-medium mb-2">Provider Requirements</h3>
              <ul className="text-gray-400 space-y-1">
                <li>• Insurance provider partnership</li>
                <li>• Insurance license or partnership with licensed insurer</li>
                <li>• Compliance with insurance regulations</li>
              </ul>
            </div>
            <SyntaxHighlighter
              language="javascript"
              style={vscDarkPlus}
              className="rounded-lg"
            >
{`// Request insurance quote
const quote = await valiant.insurance.requestQuote({
  productType: 'product',
  coverage: 10000,
  duration: 12,
  riskFactors: {
    productCategory: 'electronics',
    shippingMethod: 'express'
  }
});`}
            </SyntaxHighlighter>
          </section>

          {/* Compliance Infrastructure */}
          <section className="bg-gray-800 rounded-xl p-8 mb-8">
            <div className="flex items-center mb-6">
              <Shield className="w-8 h-8 text-purple-400 mr-4" />
              <h2 className="text-2xl font-semibold text-white">Compliance Infrastructure</h2>
            </div>
            <p className="text-gray-300 mb-4">
              Infrastructure for integrating compliance services. You maintain relationships with compliance providers while we provide integration tools.
            </p>
            <div className="bg-gray-900/50 rounded-lg p-4 mb-6">
              <h3 className="text-white font-medium mb-2">Provider Requirements</h3>
              <ul className="text-gray-400 space-y-1">
                <li>• KYC/AML provider partnership</li>
                <li>• Compliance with data protection regulations</li>
                <li>• Required regulatory certifications</li>
              </ul>
            </div>
            <SyntaxHighlighter
              language="javascript"
              style={vscDarkPlus}
              className="rounded-lg"
            >
{`// Start verification process
const verification = await valiant.compliance.startVerification({
  type: 'individual',
  provider: 'onfido',
  documents: [
    { type: 'passport', url: 'https://your-domain.com/docs/passport.jpg' }
  ]
});`}
            </SyntaxHighlighter>
          </section>

          <div className="bg-purple-600 rounded-xl p-8">
            <h2 className="text-2xl font-semibold text-white mb-4">Ready to Start Building?</h2>
            <p className="text-purple-100 mb-6">
              Get your API keys and start integrating financial services into your platform.
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <button className="inline-flex items-center justify-center space-x-2 bg-white text-purple-600 px-6 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors">
                <span>Get API Keys</span>
                <ArrowRight className="w-4 h-4" />
              </button>
              <button className="inline-flex items-center justify-center space-x-2 bg-purple-700 text-white px-6 py-3 rounded-lg font-semibold hover:bg-purple-800 transition-colors">
                <span>View Full Documentation</span>
                <ArrowRight className="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}