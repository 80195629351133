import React from 'react';
import { Code2 } from 'lucide-react';
import { SidebarItem } from './SidebarItem';
import { navigationItems } from './navigationItems';
import { useLocation } from 'react-router-dom';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

export function Sidebar({ isOpen, onClose }: SidebarProps) {
  const location = useLocation();

  return (
    <aside className={`
      fixed w-64 h-screen bg-gray-800/90 backdrop-blur-md border-r border-gray-700/50 
      transition-transform duration-200 ease-in-out z-40
      ${isOpen ? 'translate-x-0' : '-translate-x-full'}
      lg:translate-x-0
    `}>
      <div className="flex items-center space-x-2 px-6 py-4 border-b border-gray-700/50">
        <Code2 className="h-8 w-8 text-purple-500" />
        <span className="text-xl font-bold text-white">Valiant API</span>
      </div>
      
      <nav className="p-4 space-y-1">
        {navigationItems.map((item) => {
          const isActive = location.pathname === item.path || 
            (item.path !== '/dashboard' && location.pathname.startsWith(item.path));
          
          return (
            <SidebarItem
              key={item.path}
              item={item}
              isActive={isActive}
              onSelect={onClose}
            />
          );
        })}
      </nav>

      <div className="absolute bottom-0 left-0 right-0 p-4 border-t border-gray-700/50">
        <div className="bg-gray-900/50 rounded-lg p-4">
          <p className="text-sm text-gray-400 mb-2">API Status</p>
          <div className="flex items-center space-x-2">
            <div className="w-2 h-2 rounded-full bg-green-500"></div>
            <span className="text-sm text-gray-300">All Systems Operational</span>
          </div>
        </div>
      </div>
    </aside>
  );
}