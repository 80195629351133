import React from 'react';
import { Check, HelpCircle } from 'lucide-react';
import { PRICING_PLANS, type PlanId } from '../../config/pricing';
import { CheckoutButton } from '../checkout/CheckoutButton';
import { Tooltip } from '../ui/Tooltip';

interface PricingPlanCardProps {
  planId: PlanId;
}

export function PricingPlanCard({ planId }: PricingPlanCardProps) {
  const plan = PRICING_PLANS[planId];
  const isBusiness = plan.name === 'Business';
  const isEnterprise = plan.name === 'Enterprise';

  return (
    <div className={`
      bg-gray-800 rounded-xl p-8 border-2
      ${isBusiness ? 'border-purple-500 lg:scale-105' : 'border-gray-700'}
      transition-all hover:border-purple-500/50
    `}>
      {isBusiness && (
        <span className="bg-purple-500 text-white px-3 py-1 rounded-full text-sm font-medium mb-4 inline-block">
          Most Popular
        </span>
      )}

      <h3 className="text-2xl font-bold text-white mb-2">{plan.name}</h3>

      <div className="mb-6">
        <div className="flex items-baseline">
          {plan.basePrice === null ? (
            <span className="text-2xl font-bold text-white">Custom Pricing</span>
          ) : (
            <>
              <span className="text-4xl font-bold text-white">
                ${plan.basePrice}
              </span>
              <span className="text-gray-400 ml-2">/month</span>
            </>
          )}
        </div>
        {plan.trialDays && (
          <p className="text-sm text-purple-400 mt-2">
            {plan.trialDays} days free trial
          </p>
        )}
      </div>

      <ul className="space-y-4 mb-8">
        {plan.features.map((feature, index) => (
          <li key={index} className="flex items-start text-gray-300">
            <Check className="w-5 h-5 text-purple-400 mt-0.5 mr-3 flex-shrink-0" />
            <span>{feature}</span>
          </li>
        ))}
      </ul>

      {isEnterprise ? (
        <a
          href="mailto:enterprise@valiantapi.com"
          className="inline-flex items-center justify-center w-full bg-purple-600 hover:bg-purple-700 text-white px-6 py-3 rounded-lg transition-colors"
        >
          Contact Sales
        </a>
      ) : (
        <CheckoutButton
          planId={planId}
          planName={plan.name}
          className="w-full"
        />
      )}

      {plan.trialDays && (
        <p className="text-sm text-gray-400 text-center mt-4">
          No credit card required for trial
        </p>
      )}
    </div>
  );
}