import { db } from '../lib/firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { generateApiKey, validateApiKey } from '../lib/utils/apiKeyGenerator';
import { ApiError } from '../lib/utils/errors';

export async function getUserApiKeys(userId: string) {
  if (!userId) {
    throw new ApiError('User ID is required', 400, 'missing_user_id');
  }

  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      return null;
    }
    
    const data = userDoc.data();
    return data?.apiKeys || null;
  } catch (error) {
    console.error('Error fetching API keys:', error);
    throw new ApiError(
      'Failed to fetch API keys',
      500,
      'fetch_keys_error',
      error
    );
  }
}

export async function generateNewApiKey(userId: string, type: 'production' | 'test') {
  if (!userId) {
    throw new ApiError('User ID is required', 400, 'missing_user_id');
  }

  try {
    const newKey = generateApiKey(type);
    
    if (!validateApiKey(newKey)) {
      throw new ApiError('Generated invalid API key', 500, 'invalid_key_generated');
    }

    const userRef = doc(db, 'users', userId);
    const now = new Date().toISOString();

    await updateDoc(userRef, {
      [`apiKeys.${type}`]: newKey,
      [`apiKeys.${type}UpdatedAt`]: now
    });

    return newKey;
  } catch (error) {
    console.error('Error generating new API key:', error);
    throw new ApiError(
      'Failed to generate new API key',
      500,
      'generate_key_error',
      error
    );
  }
}

export async function initializeApiKeys(userId: string) {
  if (!userId) {
    throw new ApiError('User ID is required', 400, 'missing_user_id');
  }

  try {
    const userRef = doc(db, 'users', userId);
    const now = new Date().toISOString();

    const initialKeys = {
      production: generateApiKey('production'),
      test: generateApiKey('test'),
      productionUpdatedAt: now,
      testUpdatedAt: now
    };

    await setDoc(userRef, { apiKeys: initialKeys }, { merge: true });
    return initialKeys;
  } catch (error) {
    console.error('Error initializing API keys:', error);
    throw new ApiError(
      'Failed to initialize API keys',
      500,
      'init_keys_error',
      error
    );
  }
}