import React from 'react';
import { useApiKeys } from '../../hooks/useApiKeys';
import { ApiKeyCard } from './api-keys/ApiKeyCard';
import { Shield, Terminal, AlertTriangle } from 'lucide-react';

export function ApiKeys() {
  const { apiKeys, loading, error, regenerateKey } = useApiKeys();

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="w-8 h-8 border-4 border-purple-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold text-white">API Keys</h1>
          <p className="text-gray-400 mt-2">Manage your API keys and access credentials</p>
        </div>
        <button 
          onClick={() => window.open('/dashboard/docs', '_blank')}
          className="text-purple-400 hover:text-purple-300 flex items-center"
        >
          <Terminal className="w-4 h-4 mr-2" />
          View Documentation
        </button>
      </div>

      {error && (
        <div className="bg-red-500/10 border border-red-500/50 text-red-400 px-4 py-3 rounded-lg flex items-center">
          <AlertTriangle className="w-5 h-5 mr-2" />
          {error}
        </div>
      )}

      <div className="space-y-6">
        <ApiKeyCard
          type="production"
          apiKey={apiKeys?.production || ''}
          updatedAt={apiKeys?.productionUpdatedAt}
          onRegenerate={() => regenerateKey('production')}
        />

        <ApiKeyCard
          type="test"
          apiKey={apiKeys?.test || ''}
          updatedAt={apiKeys?.testUpdatedAt}
          onRegenerate={() => regenerateKey('test')}
        />
      </div>

      <div className="bg-gray-800 rounded-xl p-6">
        <div className="flex items-center mb-4">
          <Shield className="w-6 h-6 text-purple-400 mr-3" />
          <h2 className="text-xl font-semibold text-white">Security Best Practices</h2>
        </div>
        <ul className="space-y-3">
          <li className="text-gray-300 flex items-start">
            <div className="w-1.5 h-1.5 bg-purple-500 rounded-full mr-2 mt-2" />
            Never share your API keys publicly or commit them to version control
          </li>
          <li className="text-gray-300 flex items-start">
            <div className="w-1.5 h-1.5 bg-purple-500 rounded-full mr-2 mt-2" />
            Use environment variables to store API keys in your applications
          </li>
          <li className="text-gray-300 flex items-start">
            <div className="w-1.5 h-1.5 bg-purple-500 rounded-full mr-2 mt-2" />
            Rotate your API keys periodically for enhanced security
          </li>
          <li className="text-gray-300 flex items-start">
            <div className="w-1.5 h-1.5 bg-purple-500 rounded-full mr-2 mt-2" />
            Use test keys for development and testing environments
          </li>
        </ul>
      </div>
    </div>
  );
}