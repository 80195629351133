import React from 'react';
import { CreditCard, Shield, Building2, Banknote, FileCheck } from 'lucide-react';
import { ComponentConfig } from '../types';

export const COMPONENT_CONFIGS: Record<string, ComponentConfig> = {
  payment: {
    name: 'Payment Processing',
    description: 'Process payments and handle transactions',
    icon: <CreditCard className="w-5 h-5 text-purple-400" />,
    parameters: [
      {
        name: 'amount',
        type: 'number',
        required: true,
        description: 'Payment amount in cents (e.g., 1000 for $10.00)'
      },
      {
        name: 'currency',
        type: 'string',
        required: true,
        description: 'Three-letter currency code (e.g., USD, EUR, GBP)'
      },
      {
        name: 'description',
        type: 'string',
        required: true,
        description: 'Description of what the payment is for (e.g., Order #123)'
      },
      {
        name: 'paymentMethod',
        type: 'object',
        required: true,
        description: 'Payment method details',
        properties: {
          type: ['credit_card', 'bank_transfer', 'digital_wallet'],
          paymentMethodId: 'string',
          paymentProcessor: ['stripe', 'paypal', 'square']
        }
      },
      {
        name: 'customerInfo',
        type: 'object',
        required: false,
        description: 'Additional customer information',
        properties: {
          email: 'string',
          name: 'string',
          country: 'string'
        }
      }
    ]
  },
  compliance: {
    name: 'Compliance Check',
    description: 'Verify identity and compliance',
    icon: <Shield className="w-5 h-5 text-purple-400" />,
    parameters: [
      {
        name: 'verificationType',
        type: 'string',
        required: true,
        description: 'Type of verification to perform (e.g., kyc, business_verification)'
      },
      {
        name: 'identityProvider',
        type: 'string',
        required: true,
        description: 'Identity verification provider (e.g., onfido, jumio)'
      },
      {
        name: 'documentInfo',
        type: 'object',
        required: true,
        description: 'Document information for verification',
        properties: {
          documentType: ['passport', 'drivers_license', 'national_id'],
          documentUrl: 'string',
          issuingCountry: 'string'
        }
      }
    ]
  },
  banking: {
    name: 'Banking Services',
    description: 'Virtual accounts and transactions',
    icon: <Building2 className="w-5 h-5 text-purple-400" />,
    parameters: [
      {
        name: 'accountType',
        type: 'string',
        required: true,
        description: 'Type of account to create (e.g., checking, savings, business)'
      },
      {
        name: 'currency',
        type: 'string',
        required: true,
        description: 'Three-letter currency code for the account (e.g., USD, EUR)'
      },
      {
        name: 'accountHolder',
        type: 'object',
        required: true,
        description: 'Account holder information',
        properties: {
          name: 'string',
          type: ['individual', 'business'],
          country: 'string',
          taxId: 'string'
        }
      },
      {
        name: 'accountSettings',
        type: 'object',
        required: false,
        description: 'Additional account settings',
        properties: {
          interestEnabled: 'boolean',
          overdraftProtection: 'boolean',
          notificationEmail: 'string'
        }
      }
    ]
  },
  lending: {
    name: 'Lending',
    description: 'Loan applications and processing',
    icon: <Banknote className="w-5 h-5 text-purple-400" />,
    parameters: [
      {
        name: 'loanAmount',
        type: 'number',
        required: true,
        description: 'Loan amount in cents (e.g., 1000000 for $10,000.00)'
      },
      {
        name: 'loanTerm',
        type: 'number',
        required: true,
        description: 'Loan term in months (e.g., 12 for 1 year)'
      },
      {
        name: 'loanType',
        type: 'string',
        required: true,
        description: 'Type of loan (e.g., business_loan, equipment_financing)'
      },
      {
        name: 'borrowerInfo',
        type: 'object',
        required: true,
        description: 'Borrower information',
        properties: {
          businessName: 'string',
          businessType: ['llc', 'corporation', 'partnership'],
          yearsFounded: 'number',
          annualRevenue: 'number'
        }
      }
    ]
  },
  insurance: {
    name: 'Insurance',
    description: 'Insurance quotes and policies',
    icon: <FileCheck className="w-5 h-5 text-purple-400" />,
    parameters: [
      {
        name: 'coverageType',
        type: 'string',
        required: true,
        description: 'Type of insurance coverage (e.g., product_insurance, shipping_insurance)'
      },
      {
        name: 'coverageAmount',
        type: 'number',
        required: true,
        description: 'Coverage amount in cents (e.g., 1000000 for $10,000.00)'
      },
      {
        name: 'duration',
        type: 'number',
        required: true,
        description: 'Coverage duration in months'
      },
      {
        name: 'riskInfo',
        type: 'object',
        required: true,
        description: 'Risk assessment information',
        properties: {
          itemCategory: ['electronics', 'jewelry', 'general_merchandise'],
          shippingMethod: ['ground', 'air', 'sea'],
          itemValue: 'number',
          destination: 'string'
        }
      }
    ]
  }
} as const;

export function getComponentConfig(type: string): ComponentConfig {
  return COMPONENT_CONFIGS[type];
}