import React from 'react';
import { useAuth } from '../auth/AuthContext';
import { 
  Activity, 
  Users, 
  DollarSign, 
  ArrowUpRight,
  ArrowDownRight,
  Code,
  Zap,
  LineChart,
  Clock,
  Bell,
  Wallet,
  Shield,
  Loader2
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { ApiUsageChart } from './charts/ApiUsageChart';
import { RevenueChart } from './charts/RevenueChart';
import { useDashboardStats } from '../../hooks/useDashboardStats';
import { useRealtimeActivity } from '../../hooks/useRealtimeActivity';
import { formatRelativeTime } from '../../lib/utils';

export function Dashboard() {
  const { user } = useAuth();
  const { stats, loading: statsLoading, error: statsError } = useDashboardStats();
  const { 
    activities, 
    loading: activitiesLoading, 
    error: activitiesError 
  } = useRealtimeActivity();

  if (statsLoading || activitiesLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="w-8 h-8 text-purple-500 animate-spin" />
      </div>
    );
  }

  if (statsError || activitiesError) {
    return (
      <div className="text-red-400 p-4">
        {statsError || activitiesError}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Welcome Section */}
      <div className="bg-gradient-to-r from-purple-600 to-purple-800 rounded-xl p-8">
        <h1 className="text-3xl font-bold text-white mb-2">
          Welcome back, {user?.displayName || 'Developer'}
        </h1>
        <p className="text-purple-100">
          Here's what's happening with your API integration
        </p>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {stats && (
          <>
            <StatCard
              icon={<Activity className="w-6 h-6 text-purple-400" />}
              label="API Calls"
              value={stats.apiCalls.total.toLocaleString()}
              change={`${stats.apiCalls.change}%`}
              isPositive={stats.apiCalls.isPositive}
            />
            <StatCard
              icon={<Users className="w-6 h-6 text-purple-400" />}
              label="Active Users"
              value={stats.activeUsers.total.toLocaleString()}
              change={`${stats.activeUsers.change}%`}
              isPositive={stats.activeUsers.isPositive}
            />
            <StatCard
              icon={<DollarSign className="w-6 h-6 text-purple-400" />}
              label="Revenue"
              value={`$${stats.revenue.total.toLocaleString()}`}
              change={`${stats.revenue.change}%`}
              isPositive={stats.revenue.isPositive}
            />
          </>
        )}
      </div>

      {/* Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-gray-800 rounded-xl p-6">
          <h2 className="text-xl font-semibold text-white mb-6">API Usage</h2>
          <ApiUsageChart />
        </div>
        <div className="bg-gray-800 rounded-xl p-6">
          <h2 className="text-xl font-semibold text-white mb-6">Revenue</h2>
          <RevenueChart />
        </div>
      </div>

      {/* Recent Activity */}
      <div className="bg-gray-800 rounded-xl p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-white">Recent Activity</h2>
          <Link to="/dashboard/analytics" className="text-purple-400 hover:text-purple-300 text-sm">
            View all
          </Link>
        </div>
        <div className="space-y-4">
          {activities.map((activity) => (
            <div key={activity.id} className="flex items-center justify-between py-3 border-b border-gray-700 last:border-0">
              <div className="flex items-center space-x-4">
                <div className="bg-purple-500/10 p-2 rounded-lg">
                  <Clock className="w-4 h-4 text-purple-400" />
                </div>
                <div>
                  <p className="text-white">{activity.message}</p>
                  <p className="text-sm text-gray-400">{formatRelativeTime(activity.timestamp)}</p>
                </div>
              </div>
              {activity.amount && (
                <span className="text-white font-medium">{activity.amount}</span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

interface StatCardProps {
  icon: React.ReactNode;
  label: string;
  value: string;
  change: string;
  isPositive: boolean;
}

function StatCard({ icon, label, value, change, isPositive }: StatCardProps) {
  return (
    <div className="bg-gray-800 rounded-xl p-6 hover:bg-gray-750 transition-colors">
      <div className="flex items-center justify-between mb-4">
        {icon}
        <span className={`flex items-center ${
          isPositive ? 'text-green-400' : 'text-red-400'
        }`}>
          {isPositive ? (
            <ArrowUpRight className="w-4 h-4 mr-1" />
          ) : (
            <ArrowDownRight className="w-4 h-4 mr-1" />
          )}
          {change}
        </span>
      </div>
      <h3 className="text-gray-400 text-sm mb-1">{label}</h3>
      <p className="text-2xl font-bold text-white">{value}</p>
    </div>
  );
}