import React, { useCallback } from 'react';
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  useNodesState,
  useEdgesState,
  addEdge,
  Connection,
  Edge,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { WorkflowNode, WorkflowEdge } from '../types/workflow';
import { TriggerNode } from './nodes/TriggerNode';
import { ActionNode } from './nodes/ActionNode';
import { ConditionNode } from './nodes/ConditionNode';

const nodeTypes = {
  trigger: TriggerNode,
  action: ActionNode,
  condition: ConditionNode,
};

interface WorkflowCanvasProps {
  nodes: WorkflowNode[];
  edges: WorkflowEdge[];
  onNodesChange: (nodes: WorkflowNode[]) => void;
  onEdgesChange: (edges: WorkflowEdge[]) => void;
  onNodeSelect: (node: WorkflowNode | null) => void;
}

export function WorkflowCanvas({
  nodes,
  edges,
  onNodesChange,
  onEdgesChange,
  onNodeSelect,
}: WorkflowCanvasProps) {
  const onConnect = useCallback(
    (params: Connection | Edge) => {
      const newEdge = {
        ...params,
        type: 'smoothstep',
        animated: true,
      };
      onEdgesChange([...edges, newEdge as WorkflowEdge]);
    },
    [edges, onEdgesChange]
  );

  const handleNodeClick = (event: React.MouseEvent, node: WorkflowNode) => {
    onNodeSelect(node);
  };

  return (
    <div className="h-[800px] w-full bg-gray-900 rounded-xl border border-gray-800">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onConnect={onConnect}
        onNodeClick={handleNodeClick}
        nodeTypes={nodeTypes}
        defaultViewport={{ x: 0, y: 0, zoom: 1.5 }}
        minZoom={0.5}
        maxZoom={2}
        fitView
        className="bg-gray-900"
      >
        <Background color="#374151" gap={16} size={1} />
        <Controls className="bg-gray-800 border border-gray-700 rounded-lg" />
        <MiniMap
          nodeColor="#4B5563"
          maskColor="rgba(0, 0, 0, 0.2)"
          className="bg-gray-800 border border-gray-700 rounded-lg"
        />
      </ReactFlow>
    </div>
  );
}