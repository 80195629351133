import React, { useState } from 'react';
import { Copy, Eye, EyeOff, RefreshCw, Check, AlertTriangle } from 'lucide-react';
import { formatApiKey, maskApiKey } from '../../../lib/utils/apiKeyGenerator';
import { Tooltip } from '../../ui/Tooltip';

interface ApiKeyCardProps {
  type: 'production' | 'test';
  apiKey: string;
  updatedAt?: string;
  onRegenerate: () => Promise<void>;
}

export function ApiKeyCard({ type, apiKey, updatedAt, onRegenerate }: ApiKeyCardProps) {
  const [showKey, setShowKey] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isRegenerating, setIsRegenerating] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(apiKey);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy to clipboard:', error);
    }
  };

  const handleRegenerate = async () => {
    if (!confirm('Are you sure you want to regenerate this API key? The old key will stop working immediately.')) {
      return;
    }

    try {
      setIsRegenerating(true);
      await onRegenerate();
      setShowKey(true); // Show the new key after regeneration
    } catch (error) {
      console.error('Failed to regenerate key:', error);
    } finally {
      setIsRegenerating(false);
    }
  };

  const displayKey = showKey ? formatApiKey(apiKey) : maskApiKey(apiKey);

  return (
    <div className="bg-gray-800 rounded-xl p-6 space-y-4">
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-lg font-semibold text-white capitalize flex items-center">
            {type} Key
            {type === 'production' && (
              <Tooltip content="Use this key for live transactions">
                <AlertTriangle className="inline-block w-4 h-4 ml-2 text-yellow-400" />
              </Tooltip>
            )}
          </h3>
          {updatedAt && (
            <p className="text-sm text-gray-400">
              Last updated {new Date(updatedAt).toLocaleDateString()}
            </p>
          )}
        </div>
        <Tooltip content="Generate new key">
          <button
            onClick={handleRegenerate}
            disabled={isRegenerating}
            className="text-purple-400 hover:text-purple-300 disabled:opacity-50 flex items-center"
          >
            <RefreshCw className={`w-4 h-4 mr-2 ${isRegenerating ? 'animate-spin' : ''}`} />
            Regenerate
          </button>
        </Tooltip>
      </div>

      <div className="flex items-center space-x-2">
        <code className="flex-1 bg-gray-900 px-4 py-2 rounded-lg text-gray-300 font-mono overflow-x-auto whitespace-nowrap">
          {displayKey}
        </code>
        
        <Tooltip content={showKey ? 'Hide key' : 'Show key'}>
          <button
            onClick={() => setShowKey(!showKey)}
            className="p-2 hover:bg-gray-700 rounded-lg text-gray-400 hover:text-white transition-colors"
          >
            {showKey ? <EyeOff className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
          </button>
        </Tooltip>

        <Tooltip content={copied ? 'Copied!' : 'Copy to clipboard'}>
          <button
            onClick={handleCopy}
            disabled={!apiKey}
            className="p-2 hover:bg-gray-700 rounded-lg text-gray-400 hover:text-white transition-colors disabled:opacity-50"
          >
            {copied ? (
              <Check className="w-5 h-5 text-green-400" />
            ) : (
              <Copy className="w-5 h-5" />
            )}
          </button>
        </Tooltip>
      </div>

      {type === 'test' && (
        <p className="text-sm text-gray-400">
          Use this key for testing. It operates on test data and doesn't affect live systems.
        </p>
      )}
    </div>
  );
}