import { useState, useEffect } from 'react';
import { db } from '../lib/firebase';
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { useAuth } from './useAuth';

export interface ActivityItem {
  id: string;
  type: 'payment' | 'api' | 'user';
  message: string;
  amount?: string;
  timestamp: string;
}

export function useRealtimeActivity(limitCount: number = 10) {
  const { user } = useAuth();
  const [activities, setActivities] = useState<ActivityItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!user) return;

    setLoading(true);
    setError(null);

    const activitiesQuery = query(
      collection(db, 'users', user.uid, 'activities'),
      orderBy('timestamp', 'desc'),
      limit(limitCount)
    );

    const unsubscribe = onSnapshot(
      activitiesQuery,
      (snapshot) => {
        const newActivities = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as ActivityItem[];
        setActivities(newActivities);
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching activities:', err);
        setError('Failed to load recent activities');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [user, limitCount]);

  return { activities, loading, error };
}