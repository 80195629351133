import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Header } from './layout/Header';
import { Hero } from './home/Hero';
import { Products } from './home/Products';
import { Solutions } from './solutions/Solutions';
import { PricingPlans } from './pricing/PricingPlans';
import { ApiDocumentation } from './docs/ApiDocumentation';
import { DashboardLayout } from './dashboard/DashboardLayout';
import { Dashboard } from './dashboard/Dashboard';
import { ApiKeys } from './dashboard/ApiKeys';
import { Analytics } from './dashboard/Analytics';
import { Billing } from './dashboard/Billing';
import { Documentation } from './dashboard/Documentation';
import { Settings } from './dashboard/Settings';
import { LoginPage } from './auth/LoginPage';
import { SignupPage } from './auth/SignupPage';
import { ProtectedRoute } from './auth/ProtectedRoute';
import { TermsOfService } from './legal/TermsOfService';
import { NoCodeBuilder } from './nocode/NoCodeBuilder';

export function AppContent() {
  return (
    <Routes>
      <Route path="/" element={
        <>
          <Header />
          <main>
            <Hero />
            <Products />
            <Solutions />
            <PricingPlans />
          </main>
        </>
      } />
      
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/documentation" element={<ApiDocumentation />} />
      <Route path="/terms" element={<TermsOfService />} />
      <Route path="/no-code" element={<NoCodeBuilder />} />

      <Route path="/dashboard" element={
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      }>
        <Route index element={<Dashboard />} />
        <Route path="api-keys" element={<ApiKeys />} />
        <Route path="analytics" element={<Analytics />} />
        <Route path="billing" element={<Billing />} />
        <Route path="docs" element={<Documentation />} />
        <Route path="settings" element={<Settings />} />
        <Route path="no-code" element={<NoCodeBuilder />} />
      </Route>
    </Routes>
  );
}