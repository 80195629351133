export class ApiError extends Error {
  constructor(
    message: string,
    public statusCode: number,
    public code: string,
    public details?: unknown
  ) {
    super(message);
    this.name = 'ApiError';
  }

  static isApiError(error: unknown): error is ApiError {
    return error instanceof ApiError;
  }
}

interface ApiErrorResponse {
  error: {
    code: string;
    message: string;
    details?: unknown;
  };
}

export async function handleApiError(response: Response): Promise<never> {
  const errorData = await response.json().catch(() => ({
    error: {
      code: 'unknown_error',
      message: 'An unknown error occurred'
    }
  })) as ApiErrorResponse;

  throw new ApiError(
    errorData.error.message,
    response.status,
    errorData.error.code,
    errorData.error.details
  );
}

export function handleUnexpectedError(error: unknown): ApiError {
  if (ApiError.isApiError(error)) {
    return error;
  }

  if (error instanceof Error) {
    return new ApiError(
      error.message,
      500,
      'unexpected_error',
      { originalError: error }
    );
  }

  return new ApiError(
    'An unexpected error occurred',
    500,
    'unexpected_error',
    { originalError: error }
  );
}