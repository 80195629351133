import React, { useState, useEffect } from 'react';
import { Menu, X, Code2 } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { AuthButtons } from '../auth/AuthButtons';

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`fixed w-full z-50 transition-all duration-200 ${
      isScrolled ? 'bg-gray-900/95 backdrop-blur-sm shadow-lg' : 'bg-transparent'
    }`}>
      <nav className="container mx-auto px-6 py-4">
        <div className="flex items-center justify-between">
          <Link to="/" className="flex items-center space-x-2 group">
            <Code2 className="h-8 w-8 text-purple-500 transition-transform group-hover:scale-110" />
            <span className="text-xl font-bold text-white">Valiant API</span>
          </Link>
          
          <div className="hidden md:flex space-x-8">
            <NavLink href="#products">Products</NavLink>
            <NavLink href="#solutions">Solutions</NavLink>
            <NavLink href="#pricing">Pricing</NavLink>
            <Link to="/documentation" className="text-gray-300 hover:text-white transition-colors">Documentation</Link>
            <Link to="/no-code" className="text-gray-300 hover:text-white transition-colors">No-Code</Link>
          </div>

          <div className="hidden md:flex items-center space-x-4">
            <AuthButtons />
          </div>

          <button 
            className="md:hidden text-gray-300 hover:text-white transition-colors"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
          </button>
        </div>

        {isMenuOpen && (
          <div className="md:hidden mt-4 pb-4">
            <div className="flex flex-col space-y-4">
              <MobileNavLink href="#products">Products</MobileNavLink>
              <MobileNavLink href="#solutions">Solutions</MobileNavLink>
              <MobileNavLink href="#pricing">Pricing</MobileNavLink>
              <Link 
                to="/documentation" 
                className="text-gray-300 hover:text-white block py-2 transition-colors"
                onClick={() => setIsMenuOpen(false)}
              >
                Documentation
              </Link>
              <Link 
                to="/no-code" 
                className="text-gray-300 hover:text-white block py-2 transition-colors"
                onClick={() => setIsMenuOpen(false)}
              >
                No-Code
              </Link>
              <div className="pt-4 border-t border-gray-700">
                <AuthButtons />
              </div>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
}

function NavLink({ href, children }: { href: string; children: React.ReactNode }) {
  return (
    <a 
      href={href} 
      className="text-gray-300 hover:text-white transition-colors relative group"
    >
      {children}
      <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-purple-500 transition-all group-hover:w-full" />
    </a>
  );
}

function MobileNavLink({ href, children }: { href: string; children: React.ReactNode }) {
  return (
    <a 
      href={href} 
      className="text-gray-300 hover:text-white block py-2 transition-colors"
    >
      {children}
    </a>
  );
}