import React from 'react';
import { Handle, Position } from 'reactflow';
import { Zap } from 'lucide-react';

export function TriggerNode({ data }: { data: any }) {
  return (
    <div className="bg-purple-600 rounded-lg p-4 min-w-[200px]">
      <Handle type="source" position={Position.Right} />
      <div className="flex items-center space-x-2">
        <Zap className="w-5 h-5 text-white" />
        <div>
          <p className="text-white font-medium">{data.label}</p>
          {data.validationErrors?.length > 0 && (
            <p className="text-red-300 text-sm">{data.validationErrors[0]}</p>
          )}
        </div>
      </div>
    </div>
  );
}