import React from 'react';
import { 
  LayoutDashboard, 
  CreditCard, 
  Settings, 
  BarChart3, 
  Key, 
  Code2,
  Blocks 
} from 'lucide-react';
import { NavItem } from './types';

export const navigationItems: NavItem[] = [
  { 
    icon: <LayoutDashboard className="w-5 h-5" />, 
    label: 'Overview', 
    path: '/dashboard',
    description: 'View your API usage and key metrics'
  },
  { 
    icon: <Key className="w-5 h-5" />, 
    label: 'API Keys', 
    path: '/dashboard/api-keys',
    description: 'Manage your API keys and access'
  },
  { 
    icon: <Code2 className="w-5 h-5" />, 
    label: 'Documentation', 
    path: '/dashboard/docs',
    description: 'Learn how to integrate our API'
  },
  { 
    icon: <BarChart3 className="w-5 h-5" />, 
    label: 'Analytics', 
    path: '/dashboard/analytics',
    description: 'Track your API usage and performance'
  },
  { 
    icon: <CreditCard className="w-5 h-5" />, 
    label: 'Billing', 
    path: '/dashboard/billing',
    description: 'Manage your subscription and payments'
  },
  { 
    icon: <Blocks className="w-5 h-5" />, 
    label: 'No-Code Builder', 
    path: '/dashboard/no-code',
    description: 'Build API workflows without code'
  },
  { 
    icon: <Settings className="w-5 h-5" />, 
    label: 'Settings', 
    path: '/dashboard/settings',
    description: 'Configure your account settings'
  }
];