import { v4 as uuidv4 } from 'uuid';

// Generate a secure API key with prefix
export function generateApiKey(prefix: 'live' | 'test'): string {
  const uuid = uuidv4().replace(/-/g, '');
  const timestamp = Date.now().toString(36);
  return `${prefix}_${timestamp}${uuid}`;
}

// Format date to relative time
export function formatRelativeTime(date: string): string {
  const now = new Date();
  const then = new Date(date);
  const diffInSeconds = Math.floor((now.getTime() - then.getTime()) / 1000);

  if (diffInSeconds < 60) return 'just now';
  if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m ago`;
  if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h ago`;
  return `${Math.floor(diffInSeconds / 86400)}d ago`;
}