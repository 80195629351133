import { useState } from 'react';
import { useAuth } from './useAuth';
import { STRIPE_PAYMENT_LINKS, type StripePlanId } from '../lib/stripe/config';

export function useCheckout() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const startCheckout = async (planId: StripePlanId) => {
    if (!user) {
      throw new Error('User must be authenticated');
    }

    try {
      setLoading(true);
      setError(null);

      const paymentLink = STRIPE_PAYMENT_LINKS[planId];
      if (!paymentLink) {
        throw new Error('Invalid plan selected');
      }

      // Add user-specific query parameters
      const url = new URL(paymentLink);
      url.searchParams.append('prefilled_email', user.email || '');
      url.searchParams.append('client_reference_id', user.uid);

      // Redirect to Stripe Payment Link
      window.location.href = url.toString();
    } catch (err: any) {
      console.error('Checkout error:', err);
      setError(err.message || 'Failed to start checkout process');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    startCheckout,
    loading,
    error
  };
}