import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, Code2, Shield, Zap, Mail } from 'lucide-react';

export function Hero() {
  return (
    <div className="relative pt-20 pb-16 overflow-hidden">
      {/* Background gradient */}
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-purple-900/10 to-gray-900" />
      
      {/* Animated background grid */}
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_0%,#000_70%,transparent_100%)]" />

      <div className="container mx-auto px-6 relative">
        <div className="max-w-4xl mx-auto text-center">
          <div className="inline-flex items-center bg-gray-800/50 rounded-full px-3 py-1 mb-6 border border-gray-700/50 backdrop-blur-sm">
            <span className="text-purple-400 text-sm font-medium">New</span>
            <span className="text-gray-400 text-sm mx-2">|</span>
            <span className="text-gray-300 text-sm">Introducing AI-powered fraud detection</span>
          </div>

          <h1 className="text-5xl md:text-6xl font-bold text-white mb-6 leading-tight">
            Embedded Finance for the{' '}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
              Modern Web
            </span>
          </h1>
          
          <p className="text-xl text-gray-300 mb-8 leading-relaxed max-w-3xl mx-auto">
            Businesses today struggle to offer financial services without spending months or even years building them from scratch. We provide a plug-and-play embedded finance platform that allows businesses to easily integrate payments, banking, lending, and insurance—without needing a development team.
          </p>

          <p className="text-lg text-gray-400 mb-8 max-w-3xl mx-auto">
            With our no-code/low-code API, companies can offer cutting-edge financial services in a fraction of the time, all while complying with industry standards. Whether you're a small business or a large enterprise, we make financial products accessible and scalable.
          </p>

          <div className="flex flex-col sm:flex-row justify-center gap-4 mb-8">
            <Link
              to="/signup"
              className="flex items-center justify-center space-x-2 bg-purple-600 hover:bg-purple-700 text-white px-8 py-3 rounded-lg transition-all transform hover:scale-105"
            >
              <span>Start Free Trial</span>
              <ArrowRight className="w-5 h-5" />
            </Link>
            <Link
              to="/documentation"
              className="flex items-center justify-center space-x-2 bg-gray-800 hover:bg-gray-700 text-white px-8 py-3 rounded-lg border border-gray-700 transition-colors"
            >
              <Code2 className="w-5 h-5" />
              <span>View Documentation</span>
            </Link>
          </div>

          <div className="flex items-center justify-center mb-12">
            <a
              href="mailto:customersupport@valiantapi.com"
              className="inline-flex items-center text-gray-400 hover:text-purple-400 transition-colors"
            >
              <Mail className="w-4 h-4 mr-2" />
              <span>customersupport@valiantapi.com</span>
            </a>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            <FeatureCard
              icon={<Zap className="w-6 h-6 text-purple-400" />}
              title="Lightning Fast"
              description="Go live with financial services in days, not months"
            />
            <FeatureCard
              icon={<Shield className="w-6 h-6 text-purple-400" />}
              title="Bank-Grade Security"
              description="Enterprise-level security and compliance built-in"
            />
            <FeatureCard
              icon={<Code2 className="w-6 h-6 text-purple-400" />}
              title="Developer First"
              description="Built by developers, for developers"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function FeatureCard({ icon, title, description }: { icon: React.ReactNode; title: string; description: string }) {
  return (
    <div className="bg-gray-800/50 backdrop-blur-sm p-6 rounded-xl border border-gray-700/50 hover:border-purple-500/50 transition-all">
      <div className="flex items-center space-x-4 mb-4">
        <div className="p-2 bg-purple-500/10 rounded-lg">
          {icon}
        </div>
        <h3 className="text-lg font-semibold text-white">{title}</h3>
      </div>
      <p className="text-gray-400">{description}</p>
    </div>
  );
}