import React, { useState } from 'react';
import { ReactFlowProvider } from 'reactflow';
import { ComponentPalette } from './components/ComponentPalette';
import { WorkflowCanvas } from './components/WorkflowCanvas';
import { ConfigPanel } from './components/ConfigPanel';
import { ExportPanel } from './components/ExportPanel';
import { WorkflowNode, WorkflowEdge } from './types/workflow';
import { validateWorkflow } from './utils/validation';

export function NoCodeBuilder() {
  const [nodes, setNodes] = useState<WorkflowNode[]>([]);
  const [edges, setEdges] = useState<WorkflowEdge[]>([]);
  const [selectedNode, setSelectedNode] = useState<WorkflowNode | null>(null);

  const handleNodeAdd = (type: string) => {
    const newNode: WorkflowNode = {
      id: `node-${Date.now()}`,
      type,
      position: { x: 100, y: 100 },
      data: {
        type: 'action',
        label: type,
        config: {},
      },
    };
    setNodes([...nodes, newNode]);
  };

  const handleNodeUpdate = (nodeId: string, config: any) => {
    const updatedNodes = nodes.map((node) =>
      node.id === nodeId ? { ...node, data: { ...node.data, config } } : node
    );
    setNodes(updatedNodes);

    // Validate the workflow after each update
    const validationResults = validateWorkflow(updatedNodes, edges);
    const nodesWithValidation = updatedNodes.map((node) => ({
      ...node,
      data: {
        ...node.data,
        validationErrors: validationResults.errors.filter((error) => error.nodeId === node.id),
      },
    }));
    setNodes(nodesWithValidation);
  };

  return (
    <div className="min-h-screen bg-gray-900 pt-24">
      <div className="container mx-auto px-6">
        <div className="flex gap-6">
          {/* Left Sidebar - Component Palette */}
          <div className="w-80">
            <ComponentPalette onAddComponent={handleNodeAdd} />
          </div>

          {/* Main Canvas */}
          <div className="flex-1">
            <ReactFlowProvider>
              <WorkflowCanvas
                nodes={nodes}
                edges={edges}
                onNodesChange={setNodes}
                onEdgesChange={setEdges}
                onNodeSelect={setSelectedNode}
              />
            </ReactFlowProvider>
          </div>

          {/* Right Sidebar - Configuration & Export */}
          <div className="w-96 space-y-6">
            {selectedNode && (
              <ConfigPanel
                node={selectedNode}
                onUpdate={(config) => handleNodeUpdate(selectedNode.id, config)}
              />
            )}
            <ExportPanel nodes={nodes} edges={edges} />
          </div>
        </div>
      </div>
    </div>
  );
}