import { useState, useEffect } from 'react';
import { useAuth } from './useAuth';
import { db } from '../lib/firebase';
import { doc, onSnapshot } from 'firebase/firestore';

export interface BillingInfo {
  currentPeriodStart: string;
  currentPeriodEnd: string;
  status: 'active' | 'canceled' | 'past_due';
  cancelAtPeriodEnd: boolean;
  amount: number;
  currency: string;
  invoices: Array<{
    id: string;
    amount: number;
    status: string;
    date: string;
  }>;
}

export function useBilling() {
  const { user } = useAuth();
  const [billingInfo, setBillingInfo] = useState<BillingInfo | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!user) {
      setBillingInfo(null);
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);

    // Use onSnapshot for real-time updates
    const unsubscribe = onSnapshot(
      doc(db, 'users', user.uid),
      (doc) => {
        if (doc.exists() && doc.data()?.billing) {
          setBillingInfo(doc.data()?.billing as BillingInfo);
        } else {
          // Set default billing info for new users
          setBillingInfo({
            currentPeriodStart: new Date().toISOString(),
            currentPeriodEnd: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(),
            status: 'active',
            cancelAtPeriodEnd: false,
            amount: 0,
            currency: 'USD',
            invoices: []
          });
        }
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching billing info:', err);
        setError('Failed to load billing information');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [user]);

  return { billingInfo, loading, error };
}