import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
import { auth } from '../../lib/firebase';
import { useAuth } from './AuthContext';

export function AuthButtons() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleGoogleSignIn = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      if (result.user) {
        navigate('/dashboard');
      }
    } catch (error: any) {
      console.error('Error signing in with Google:', error);
      setError('An error occurred during sign in. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  if (user) {
    return (
      <>
        <button 
          onClick={() => navigate('/dashboard')}
          className="text-gray-300 hover:text-white transition-colors"
        >
          Dashboard
        </button>
        <button
          onClick={handleSignOut}
          className="bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 rounded-lg transition-colors"
        >
          Sign Out
        </button>
      </>
    );
  }

  return (
    <div className="flex flex-col md:flex-row items-center gap-4">
      {error && (
        <div className="text-red-400 text-sm mb-2 md:mb-0">
          {error}
        </div>
      )}
      <button
        onClick={handleGoogleSignIn}
        disabled={isLoading}
        className="text-gray-300 hover:text-white transition-colors disabled:opacity-50"
      >
        Sign in
      </button>
      <button
        onClick={handleGoogleSignIn}
        disabled={isLoading}
        className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-lg transition-colors disabled:opacity-50 flex items-center space-x-2"
      >
        {isLoading ? (
          <>
            <span className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></span>
            <span>Connecting...</span>
          </>
        ) : (
          <span>Get Started</span>
        )}
      </button>
    </div>
  );
}