import { useState, useEffect } from 'react';
import { db } from '../lib/firebase';
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { useAuth } from './useAuth';

export interface ChartDataPoint {
  date: string;
  value: number;
}

export function useRealtimeChartData(dataType: 'apiCalls' | 'revenue', days: number = 7) {
  const { user } = useAuth();
  const [data, setData] = useState<ChartDataPoint[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!user) return;

    setLoading(true);
    setError(null);

    const chartDataQuery = query(
      collection(db, 'users', user.uid, `chartData_${dataType}`),
      orderBy('date', 'desc'),
      limit(days)
    );

    const unsubscribe = onSnapshot(
      chartDataQuery,
      (snapshot) => {
        const newData = snapshot.docs
          .map(doc => doc.data() as ChartDataPoint)
          .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        setData(newData);
        setLoading(false);
      },
      (err) => {
        console.error(`Error fetching ${dataType} chart data:`, err);
        setError(`Failed to load ${dataType} chart data`);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [user, dataType, days]);

  return { data, loading, error };
}