import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';
import { NavItem } from './types';

interface SidebarItemProps {
  item: NavItem;
  isActive: boolean;
  onSelect?: () => void;
}

export function SidebarItem({ item, isActive, onSelect }: SidebarItemProps) {
  return (
    <Link
      to={item.path}
      className={`
        flex items-center space-x-3 px-4 py-3 rounded-lg transition-all
        group relative
        ${isActive 
          ? 'bg-purple-500/10 text-purple-400' 
          : 'text-gray-400 hover:bg-gray-700/50 hover:text-white'
        }
      `}
      onClick={onSelect}
    >
      <div className={`
        p-2 rounded-lg transition-colors
        ${isActive ? 'bg-purple-500/20' : 'group-hover:bg-gray-800/50'}
      `}>
        {item.icon}
      </div>
      <span className="font-medium">{item.label}</span>
      <ChevronRight className={`
        w-4 h-4 ml-auto transition-transform
        ${isActive ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'}
      `} />
      
      {/* Tooltip */}
      <div className="absolute left-full ml-2 px-3 py-1 bg-gray-700 text-sm text-white rounded-md 
        opacity-0 group-hover:opacity-100 pointer-events-none transition-opacity
        whitespace-nowrap z-50 hidden lg:block">
        {item.description}
      </div>
    </Link>
  );
}