import { useState, useEffect } from 'react';
import { useAuth } from './useAuth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { PlanId } from '../config/pricing';

export function usePlan() {
  const { user } = useAuth();
  const [currentPlan, setCurrentPlan] = useState<PlanId>('starter');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchPlan() {
      if (!user) {
        setCurrentPlan('starter');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        setError(null);
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const data = userDoc.data();
        
        if (data?.subscription?.planId) {
          setCurrentPlan(data.subscription.planId);
        } else {
          // Initialize with starter plan if no subscription exists
          setCurrentPlan('starter');
        }
      } catch (err) {
        console.error('Error fetching plan:', err);
        setError('Failed to load subscription details. Please try again.');
      } finally {
        setLoading(false);
      }
    }

    fetchPlan();
  }, [user]);

  return { currentPlan, loading, error };
}