import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics, isSupported } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyDqyRl9ALMxk5VY-BKmDULWMfTeQuVjBM0",
  authDomain: "valiantapi.firebaseapp.com",
  projectId: "valiantapi",
  storageBucket: "valiantapi.appspot.com",
  messagingSenderId: "315085704113",
  appId: "1:315085704113:web:97b5131836eefaa78dcb90",
  measurementId: "G-JV8XNS3STD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
export const auth = getAuth(app);
export const db = getFirestore(app);

// Initialize analytics only in browser environment
let analytics = null;
if (typeof window !== 'undefined') {
  isSupported().then(yes => yes && getAnalytics(app));
}

export { analytics };