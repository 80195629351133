import React from 'react';
import { AlertTriangle, Bell, Loader2 } from 'lucide-react';
import { usePlan } from '../../../hooks/usePlan';
import { PRICING_PLANS } from '../../../config/pricing';
import { PlanUsage } from './PlanUsage';
import { PaymentMethod } from './PaymentMethod';
import { BillingHistory } from './BillingHistory';
import { Button } from '../../ui/Button';
import { useSubscription } from '../../../hooks/useSubscription';
import { useFeatures } from '../../../hooks/useFeatures';
import { useBilling } from '../../../hooks/useBilling';

export function BillingOverview() {
  const { currentPlan, loading: planLoading, error: planError } = usePlan();
  const { subscription, loading: subLoading, error: subError } = useSubscription();
  const { features, loading: featuresLoading, error: featuresError } = useFeatures();
  const { billingInfo, loading: billingLoading, error: billingError } = useBilling();

  const loading = planLoading || subLoading || featuresLoading || billingLoading;
  const error = planError || subError || featuresError || billingError;

  if (loading) {
    return (
      <div className="min-h-[60vh] flex items-center justify-center">
        <div className="flex flex-col items-center space-y-4">
          <Loader2 className="w-8 h-8 text-purple-500 animate-spin" />
          <p className="text-gray-400">Loading billing information...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-[60vh] flex items-center justify-center">
        <div className="bg-red-500/10 border border-red-500/50 rounded-lg p-6 max-w-md text-center">
          <AlertTriangle className="w-12 h-12 text-red-400 mx-auto mb-4" />
          <h3 className="text-lg font-semibold text-white mb-2">Failed to Load Billing</h3>
          <p className="text-gray-400 mb-4">{error}</p>
          <button 
            onClick={() => window.location.reload()}
            className="text-red-400 hover:text-red-300 text-sm"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  const plan = PRICING_PLANS[currentPlan];

  if (!plan || !billingInfo) {
    return (
      <div className="min-h-[60vh] flex items-center justify-center">
        <div className="bg-yellow-500/10 border border-yellow-500/50 rounded-lg p-6 max-w-md text-center">
          <AlertTriangle className="w-12 h-12 text-yellow-400 mx-auto mb-4" />
          <h3 className="text-lg font-semibold text-white mb-2">No Active Plan</h3>
          <p className="text-gray-400 mb-4">Please select a plan to continue using our services.</p>
          <Button variant="primary" onClick={() => window.location.href = '/pricing'}>
            View Plans
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold text-white mb-2">Billing & Subscription</h1>
          <p className="text-gray-400">Manage your plan and payment details</p>
        </div>
      </div>

      {/* Current Plan */}
      <div className="bg-gray-800 rounded-xl p-6">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h2 className="text-xl font-semibold text-white mb-2">Current Plan</h2>
            <p className="text-purple-400 text-lg">
              {plan.name} Plan
            </p>
          </div>
          <div className="text-right">
            <p className="text-2xl font-bold text-white">
              ${billingInfo.amount}
              <span className="text-gray-400 text-sm font-normal">/month</span>
            </p>
            {subscription && (
              <p className="text-gray-400 text-sm">
                Next billing date: {new Date(subscription.currentPeriodEnd).toLocaleDateString()}
              </p>
            )}
          </div>
        </div>

        {features && <PlanUsage features={features} plan={plan} />}

        {subscription?.cancelAtPeriodEnd && (
          <div className="mt-6 bg-yellow-500/10 border border-yellow-500/50 rounded-lg p-4">
            <div className="flex items-start">
              <AlertTriangle className="w-5 h-5 text-yellow-500 mt-0.5 mr-3" />
              <div>
                <p className="text-yellow-500 font-medium">
                  Your subscription will end on {new Date(subscription.currentPeriodEnd).toLocaleDateString()}
                </p>
                <p className="text-gray-400 text-sm mt-1">
                  You'll lose access to premium features after this date.
                </p>
                <Button
                  variant="outline"
                  size="sm"
                  className="mt-4"
                  onClick={() => window.location.href = 'https://billing.stripe.com/p/login/9AQ5n19ya9aDd5mfYY'}
                >
                  Manage Subscription
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Payment Method */}
      <PaymentMethod />

      {/* Billing History */}
      <BillingHistory />
    </div>
  );
}