import React from 'react';

interface ProgressProps {
  value: number;
  max?: number;
}

export function Progress({ value, max = 100 }: ProgressProps) {
  const percentage = Math.min(Math.max(0, value), max);
  const isWarning = percentage > 80;

  return (
    <div className="bg-gray-700 h-2 rounded-full overflow-hidden">
      <div
        className={`h-full transition-all ${
          isWarning ? 'bg-yellow-500' : 'bg-purple-500'
        }`}
        style={{ width: `${percentage}%` }}
      />
    </div>
  );
}