import { WorkflowNode, WorkflowEdge, WorkflowExport } from '../types/workflow';

export function exportToBubble(nodes: WorkflowNode[], edges: WorkflowEdge[]): WorkflowExport {
  return {
    platform: 'bubble',
    nodes,
    edges,
    metadata: {
      name: 'Valiant API Workflow',
      description: 'Generated workflow for Bubble.io',
      version: '1.0',
      created: new Date().toISOString(),
    },
  };
}

export function exportToZapier(nodes: WorkflowNode[], edges: WorkflowEdge[]): WorkflowExport {
  return {
    platform: 'zapier',
    nodes,
    edges,
    metadata: {
      name: 'Valiant API Workflow',
      description: 'Generated workflow for Zapier',
      version: '1.0',
      created: new Date().toISOString(),
    },
  };
}