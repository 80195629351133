import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader2, ArrowRight } from 'lucide-react';
import { useCheckout } from '../../hooks/useCheckout';
import { useAuth } from '../../hooks/useAuth';
import type { StripePlanId } from '../../lib/stripe/config';

interface CheckoutButtonProps {
  planId: StripePlanId;
  planName: string;
  className?: string;
}

export function CheckoutButton({ 
  planId, 
  planName, 
  className = '' 
}: CheckoutButtonProps) {
  const { startCheckout, loading, error } = useCheckout();
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleClick = async () => {
    if (!user) {
      navigate('/signup', { 
        state: { 
          returnTo: '/pricing',
          selectedPlan: planId 
        }
      });
      return;
    }

    try {
      await startCheckout(planId);
    } catch (err) {
      console.error('Checkout failed:', err);
    }
  };

  return (
    <div className="space-y-2">
      <button
        onClick={handleClick}
        disabled={loading}
        className={`
          inline-flex items-center justify-center space-x-2
          bg-purple-600 hover:bg-purple-700 text-white
          px-6 py-3 rounded-lg font-medium
          transition-all transform hover:scale-105
          focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2
          disabled:opacity-50 disabled:cursor-not-allowed
          ${className}
        `}
      >
        {loading ? (
          <>
            <Loader2 className="w-5 h-5 animate-spin" />
            <span>Processing...</span>
          </>
        ) : (
          <>
            <span>Get Started with {planName}</span>
            <ArrowRight className="w-5 h-5" />
          </>
        )}
      </button>
      {error && (
        <p className="text-sm text-red-400 text-center">{error}</p>
      )}
    </div>
  );
}