import React from 'react';
import { Bell, Search } from 'lucide-react';
import { useAuth } from '../../auth/AuthContext';
import { useLocation } from 'react-router-dom';
import { navigationItems } from './navigationItems';
import { UserMenu } from './UserMenu';

export function Header() {
  const { user } = useAuth();
  const location = useLocation();

  const currentItem = navigationItems.find(item => 
    location.pathname === item.path || 
    (item.path !== '/dashboard' && location.pathname.startsWith(item.path))
  );

  return (
    <header className="bg-gray-800/50 backdrop-blur-md border-b border-gray-700/50 h-16 flex items-center justify-between px-6 sticky top-0 z-30">
      <div className="flex items-center flex-1">
        <h1 className="text-xl font-semibold text-white">
          {currentItem?.label || 'Dashboard'}
        </h1>
        {currentItem?.description && (
          <span className="ml-4 text-sm text-gray-400 hidden md:block">
            {currentItem.description}
          </span>
        )}
      </div>

      <div className="flex-1 max-w-md mx-4 hidden md:block">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
          <input
            type="text"
            placeholder="Search..."
            className="w-full bg-gray-900/50 border border-gray-700/50 rounded-lg pl-10 pr-4 py-2 text-gray-300 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
          />
        </div>
      </div>
      
      <div className="flex items-center space-x-4">
        <button 
          className="text-gray-400 hover:text-white relative p-2 rounded-lg hover:bg-gray-700/50 transition-colors"
        >
          <Bell className="w-5 h-5" />
          <span className="absolute -top-0.5 -right-0.5 w-2 h-2 bg-purple-500 rounded-full ring-2 ring-gray-800"></span>
        </button>
        
        <UserMenu user={user} />
      </div>
    </header>
  );
}