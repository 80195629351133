import { useState, useEffect } from 'react';
import { useAuth } from './useAuth';
import { PlanFeatures } from '../lib/features/types';
import { getUserFeatures } from '../services/featureService';
import { isFeatureAvailable, hasAnalyticsFeature } from '../lib/features/planFeatures';

export function useFeatures() {
  const { user } = useAuth();
  const [features, setFeatures] = useState<PlanFeatures | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function loadFeatures() {
      if (!user) {
        setFeatures(null);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const userFeatures = await getUserFeatures(user.uid);
        setFeatures(userFeatures);
      } catch (err) {
        console.error('Error loading user features:', err);
        setError('Failed to load account features');
      } finally {
        setLoading(false);
      }
    }

    loadFeatures();
  }, [user]);

  const checkFeature = (featureName: keyof PlanFeatures) => {
    if (!features) return false;
    return isFeatureAvailable(features, featureName);
  };

  const checkAnalyticsFeature = (featureName: string) => {
    if (!features) return false;
    return hasAnalyticsFeature(features, featureName);
  };

  return {
    features,
    loading,
    error,
    checkFeature,
    checkAnalyticsFeature
  };
}