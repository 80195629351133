import React from 'react';
import { Progress } from '../../ui/Progress';
import { PlanFeatures } from '../../../lib/features/types';
import { PricingTier } from '../../../config/pricing';

interface PlanUsageProps {
  features: PlanFeatures;
  plan: PricingTier;
}

export function PlanUsage({ features, plan }: PlanUsageProps) {
  const apiUsagePercent = (features.apiCalls.used / features.apiCalls.limit) * 100;
  
  return (
    <div className="space-y-4">
      <div>
        <div className="flex items-center justify-between text-sm mb-2">
          <span className="text-gray-300">API calls used this month</span>
          <span className="text-gray-300">
            {features.apiCalls.used.toLocaleString()} / {features.apiCalls.limit.toLocaleString()}
          </span>
        </div>
        <Progress value={apiUsagePercent} />
      </div>

      <div className="grid md:grid-cols-2 gap-6 mt-6">
        <div>
          <h3 className="text-white font-medium mb-3">Features</h3>
          <ul className="space-y-2">
            <li className="text-gray-300">Analytics: {features.analytics.type}</li>
            <li className="text-gray-300">Support: {features.support.type}</li>
            <li className="text-gray-300">Data Retention: {features.dataRetention} days</li>
          </ul>
        </div>
        <div>
          <h3 className="text-white font-medium mb-3">Add-ons</h3>
          {plan.addOns ? (
            <ul className="space-y-2">
              {plan.addOns.map((addon, index) => (
                <li key={index} className="text-gray-300">
                  {addon.description} - ${addon.price}/month
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-400">No add-ons available for this plan</p>
          )}
        </div>
      </div>
    </div>
  );
}