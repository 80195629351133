import { db } from '../lib/firebase';
import { doc, setDoc, updateDoc, getDoc } from 'firebase/firestore';
import { PRICING_TIERS, PlanTier } from '../config/plans';

interface SubscriptionData {
  planId: PlanTier;
  status: 'active' | 'canceled' | 'past_due';
  currentPeriodEnd: string;
  cancelAtPeriodEnd: boolean;
  stripeSubscriptionId?: string;
}

export async function updateSubscription(userId: string, subscriptionData: SubscriptionData) {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      subscription: subscriptionData
    });
  } catch (error) {
    console.error('Error updating subscription:', error);
    throw new Error('Failed to update subscription');
  }
}

export async function getSubscription(userId: string): Promise<SubscriptionData | null> {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) return null;
    return userDoc.data()?.subscription || null;
  } catch (error) {
    console.error('Error fetching subscription:', error);
    throw new Error('Failed to fetch subscription');
  }
}

export async function cancelSubscription(userId: string): Promise<void> {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      throw new Error('User not found');
    }

    const subscription = userDoc.data()?.subscription;
    if (!subscription?.stripeSubscriptionId) {
      throw new Error('No active subscription found');
    }

    // Call Stripe to cancel subscription
    const response = await fetch('/.netlify/functions/cancel-subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        subscriptionId: subscription.stripeSubscriptionId,
      }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to cancel subscription');
    }

    // Update local subscription status
    await updateDoc(userRef, {
      'subscription.cancelAtPeriodEnd': true,
      'subscription.status': 'canceled'
    });
  } catch (error) {
    console.error('Error canceling subscription:', error);
    throw error;
  }
}

export async function reactivateSubscription(userId: string): Promise<void> {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      throw new Error('User not found');
    }

    const subscription = userDoc.data()?.subscription;
    if (!subscription?.stripeSubscriptionId) {
      throw new Error('No subscription found');
    }

    // Call Stripe to reactivate subscription
    const response = await fetch('/.netlify/functions/reactivate-subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        subscriptionId: subscription.stripeSubscriptionId,
      }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to reactivate subscription');
    }

    // Update local subscription status
    await updateDoc(userRef, {
      'subscription.cancelAtPeriodEnd': false,
      'subscription.status': 'active'
    });
  } catch (error) {
    console.error('Error reactivating subscription:', error);
    throw error;
  }
}