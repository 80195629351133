import { useState, useEffect } from 'react';
import { useAuth } from './useAuth';
import { db } from '../lib/firebase';
import { doc, onSnapshot } from 'firebase/firestore';

interface PaymentMethod {
  id: string;
  last4: string;
  expMonth: string;
  expYear: string;
  brand: string;
}

export function usePaymentMethod() {
  const { user } = useAuth();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!user) return;

    setLoading(true);
    setError(null);

    // Subscribe to payment method updates
    const unsubscribe = onSnapshot(
      doc(db, 'users', user.uid),
      (doc) => {
        const data = doc.data();
        if (data?.paymentMethod) {
          setPaymentMethod(data.paymentMethod);
        } else {
          setPaymentMethod(null);
        }
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching payment method:', err);
        setError('Failed to load payment method');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [user]);

  const updatePaymentMethod = async () => {
    if (!user) {
      throw new Error('User must be authenticated');
    }

    setLoading(true);
    setError(null);

    try {
      // Payment method update is handled via Stripe Payment Link redirect
      return true;
    } catch (err) {
      console.error('Error updating payment method:', err);
      setError('Failed to update payment method');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    paymentMethod,
    loading,
    error,
    updatePaymentMethod
  };
}