import { useState, useEffect } from 'react';
import { useAuth } from './useAuth';
import { getUserApiKeys, generateNewApiKey } from '../services/apiKeyService';
import { ApiError } from '../lib/utils/errors';

interface ApiKeys {
  production: string;
  test: string;
  productionUpdatedAt?: string;
  testUpdatedAt?: string;
}

export function useApiKeys() {
  const { user } = useAuth();
  const [apiKeys, setApiKeys] = useState<ApiKeys | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (user) {
      loadApiKeys();
    }
  }, [user]);

  const loadApiKeys = async () => {
    if (!user) return;
    
    try {
      setLoading(true);
      setError(null);
      const keys = await getUserApiKeys(user.uid);
      setApiKeys(keys);
    } catch (err) {
      const message = err instanceof ApiError ? err.message : 'Failed to load API keys';
      setError(message);
      console.error('Error loading API keys:', err);
    } finally {
      setLoading(false);
    }
  };

  const regenerateKey = async (type: 'production' | 'test') => {
    if (!user) throw new Error('User not authenticated');
    
    try {
      setError(null);
      const newKey = await generateNewApiKey(user.uid, type);
      setApiKeys(prev => prev ? {
        ...prev,
        [type]: newKey,
        [`${type}UpdatedAt`]: new Date().toISOString()
      } : null);
      return newKey;
    } catch (err) {
      const message = err instanceof ApiError ? err.message : 'Failed to generate new key';
      setError(message);
      console.error('Error regenerating key:', err);
      throw err;
    }
  };

  return {
    apiKeys,
    loading,
    error,
    regenerateKey,
    refresh: loadApiKeys
  };
}