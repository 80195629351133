import React from 'react';
import { Shield, AlertTriangle } from 'lucide-react';

export function TermsOfService() {
  return (
    <div className="min-h-screen bg-gray-900 pt-24">
      <div className="container mx-auto px-6">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold text-white mb-6">Terms of Service</h1>
          
          {/* Infrastructure Provider Notice */}
          <div className="bg-yellow-500/10 border border-yellow-500/50 rounded-xl p-6 mb-8">
            <div className="flex items-start">
              <AlertTriangle className="w-6 h-6 text-yellow-400 mt-1 mr-3" />
              <div>
                <h2 className="text-lg font-semibold text-yellow-400 mb-2">Important Notice</h2>
                <p className="text-gray-300">
                  Valiant API is an infrastructure provider only. We do not provide financial services directly. Users are responsible for all financial services, compliance, and regulatory requirements.
                </p>
              </div>
            </div>
          </div>

          {/* Service Description */}
          <section className="bg-gray-800 rounded-xl p-8 mb-8">
            <h2 className="text-2xl font-semibold text-white mb-4">1. Service Description</h2>
            <div className="space-y-4 text-gray-300">
              <p>
                Valiant API provides infrastructure tools and APIs that enable users to build and integrate financial services into their applications. Our services include:
              </p>
              <ul className="list-disc pl-6 space-y-2">
                <li>API infrastructure for payments, banking, lending, and insurance</li>
                <li>Development tools and documentation</li>
                <li>Integration support and technical assistance</li>
                <li>Infrastructure monitoring and analytics</li>
              </ul>
            </div>
          </section>

          {/* User Responsibilities */}
          <section className="bg-gray-800 rounded-xl p-8 mb-8">
            <h2 className="text-2xl font-semibold text-white mb-4">2. User Responsibilities</h2>
            <div className="space-y-4 text-gray-300">
              <p>By using Valiant API, you acknowledge and agree that you are responsible for:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Obtaining and maintaining all necessary licenses and regulatory approvals</li>
                <li>Compliance with all applicable financial regulations and laws</li>
                <li>KYC/AML procedures and customer due diligence</li>
                <li>Data security and privacy compliance</li>
                <li>Managing relationships with financial service providers</li>
                <li>Customer support for your financial products</li>
              </ul>
            </div>
          </section>

          {/* Data and Security */}
          <section className="bg-gray-800 rounded-xl p-8 mb-8">
            <h2 className="text-2xl font-semibold text-white mb-4">3. Data and Security</h2>
            <div className="space-y-4 text-gray-300">
              <p>Users must:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Implement appropriate security measures to protect sensitive data</li>
                <li>Not store sensitive financial data on Valiant API infrastructure</li>
                <li>Maintain secure encryption for data transmission</li>
                <li>Report any security incidents promptly</li>
              </ul>
            </div>
          </section>

          {/* Compliance Requirements */}
          <section className="bg-gray-800 rounded-xl p-8 mb-8">
            <h2 className="text-2xl font-semibold text-white mb-4">4. Compliance Requirements</h2>
            <div className="space-y-4 text-gray-300">
              <p>Users must comply with:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>All applicable financial regulations</li>
                <li>Data protection laws (GDPR, CCPA, etc.)</li>
                <li>Industry security standards</li>
                <li>Anti-money laundering regulations</li>
              </ul>
            </div>
          </section>

          {/* Liability and Disclaimers */}
          <section className="bg-gray-800 rounded-xl p-8 mb-8">
            <h2 className="text-2xl font-semibold text-white mb-4">5. Liability and Disclaimers</h2>
            <div className="space-y-4 text-gray-300">
              <p>Valiant API:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Is not responsible for financial services provided by users</li>
                <li>Makes no guarantees regarding financial outcomes</li>
                <li>Does not provide financial, legal, or compliance advice</li>
                <li>Is not liable for user compliance failures</li>
              </ul>
            </div>
          </section>

          {/* Contact Information */}
          <section className="bg-purple-600 rounded-xl p-8">
            <div className="flex items-center mb-4">
              <Shield className="w-6 h-6 text-white mr-3" />
              <h2 className="text-2xl font-semibold text-white">Questions About Compliance?</h2>
            </div>
            <p className="text-purple-100 mb-6">
              Contact our compliance team for clarification on your responsibilities when using our infrastructure.
            </p>
            <a
              href="mailto:compliance@valiantapi.com"
              className="inline-flex items-center bg-white text-purple-600 px-6 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors"
            >
              Contact Compliance Team
            </a>
          </section>
        </div>
      </div>
    </div>
  );
}