import React from 'react';
import { COMPONENT_CONFIGS } from '../config/components';

interface ComponentPaletteProps {
  onAddBlock: (blockType: string) => void;
}

export function ComponentPalette({ onAddBlock }: ComponentPaletteProps) {
  return (
    <div className="bg-gray-800 rounded-xl p-6">
      <h2 className="text-lg font-semibold text-white mb-4">API Components</h2>
      <div className="space-y-3">
        {Object.entries(COMPONENT_CONFIGS).map(([type, config]) => (
          <div
            key={type}
            draggable
            onDragStart={(e) => {
              e.dataTransfer.setData('application/json', JSON.stringify({ type }));
            }}
            className="flex items-center space-x-3 p-3 bg-gray-700 rounded-lg hover:bg-gray-650 transition-colors cursor-move"
          >
            <div className="p-2 bg-gray-600 rounded-lg">
              {config.icon}
            </div>
            <div className="text-left">
              <h3 className="text-white font-medium">{config.name}</h3>
              <p className="text-sm text-gray-400">{config.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}