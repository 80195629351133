import React, { useState } from 'react';
import { dump as yamlDump } from 'js-yaml';
import { WorkflowNode, WorkflowEdge } from '../types/workflow';
import { exportToBubble, exportToZapier } from '../utils/export';

interface ExportPanelProps {
  nodes: WorkflowNode[];
  edges: WorkflowEdge[];
}

export function ExportPanel({ nodes, edges }: ExportPanelProps) {
  const [format, setFormat] = useState<'json' | 'yaml'>('json');
  const [platform, setPlatform] = useState<'bubble' | 'zapier'>('bubble');
  const [copied, setCopied] = useState(false);

  const getExportedData = () => {
    const exportData = platform === 'bubble' 
      ? exportToBubble(nodes, edges)
      : exportToZapier(nodes, edges);

    return format === 'json' 
      ? JSON.stringify(exportData, null, 2)
      : yamlDump(exportData);
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(getExportedData());
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  return (
    <div className="bg-gray-800 rounded-xl p-6">
      <h2 className="text-lg font-semibold text-white mb-4">Export Workflow</h2>
      
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-400 mb-2">
            Platform
          </label>
          <select
            value={platform}
            onChange={(e) => setPlatform(e.target.value as 'bubble' | 'zapier')}
            className="w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 text-white"
          >
            <option value="bubble">Bubble.io</option>
            <option value="zapier">Zapier</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-400 mb-2">
            Format
          </label>
          <select
            value={format}
            onChange={(e) => setFormat(e.target.value as 'json' | 'yaml')}
            className="w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 text-white"
          >
            <option value="json">JSON</option>
            <option value="yaml">YAML</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-400 mb-2">
            Configuration
          </label>
          <pre className="bg-gray-900 p-4 rounded-lg text-gray-300 text-sm overflow-auto max-h-60">
            {getExportedData()}
          </pre>
        </div>

        <button
          onClick={handleCopy}
          className="w-full bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-lg transition-colors"
        >
          {copied ? 'Copied!' : 'Copy Configuration'}
        </button>
      </div>
    </div>
  );
}