import React from 'react';
import { CreditCard } from 'lucide-react';
import { Button } from '../../ui/Button';
import { usePaymentMethod } from '../../../hooks/usePaymentMethod';
import { STRIPE_PAYMENT_LINKS } from '../../../lib/stripe/config';
import { usePlan } from '../../../hooks/usePlan';

export function PaymentMethod() {
  const { currentPlan } = usePlan();
  const { paymentMethod, loading, error, updatePaymentMethod } = usePaymentMethod();

  const handleUpdatePaymentMethod = () => {
    const paymentLink = STRIPE_PAYMENT_LINKS[currentPlan];
    if (!paymentLink) {
      console.error('No payment link found for plan:', currentPlan);
      return;
    }

    // Add update_payment_method=true parameter to the URL
    const updateUrl = new URL(paymentLink);
    updateUrl.searchParams.append('update_payment_method', 'true');
    
    // Redirect to Stripe payment link
    window.location.href = updateUrl.toString();
  };

  if (loading) {
    return (
      <div className="bg-gray-800 rounded-xl p-6">
        <h2 className="text-xl font-semibold text-white mb-4">Payment Method</h2>
        <div className="animate-pulse flex items-center space-x-4">
          <div className="bg-gray-700 p-3 rounded-lg">
            <CreditCard className="w-6 h-6 text-gray-600" />
          </div>
          <div className="space-y-2">
            <div className="h-4 bg-gray-700 rounded w-32"></div>
            <div className="h-3 bg-gray-700 rounded w-24"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-gray-800 rounded-xl p-6">
        <h2 className="text-xl font-semibold text-white mb-4">Payment Method</h2>
        <div className="text-red-400">{error}</div>
        <Button variant="outline" className="mt-4" onClick={handleUpdatePaymentMethod}>
          Try Again
        </Button>
      </div>
    );
  }

  return (
    <div className="bg-gray-800 rounded-xl p-6">
      <h2 className="text-xl font-semibold text-white mb-4">Payment Method</h2>
      <div className="flex items-center space-x-4">
        <div className="bg-gray-700 p-3 rounded-lg">
          <CreditCard className="w-6 h-6 text-gray-400" />
        </div>
        <div>
          {paymentMethod ? (
            <>
              <p className="text-white">•••• •••• •••• {paymentMethod.last4}</p>
              <p className="text-gray-400 text-sm">
                Expires {paymentMethod.expMonth}/{paymentMethod.expYear}
              </p>
            </>
          ) : (
            <p className="text-gray-400">No payment method on file</p>
          )}
        </div>
        <Button 
          variant="outline" 
          className="ml-auto"
          onClick={handleUpdatePaymentMethod}
        >
          {paymentMethod ? 'Update' : 'Add Payment Method'}
        </Button>
      </div>
    </div>
  );
}