import { WorkflowNode, WorkflowEdge, ValidationResult } from '../types/workflow';

interface ValidationError {
  nodeId: string;
  message: string;
}

export function validateWorkflow(
  nodes: WorkflowNode[],
  edges: WorkflowEdge[]
): ValidationResult {
  const errors: ValidationError[] = [];

  // Check for required trigger
  const triggers = nodes.filter((node) => node.data.type === 'trigger');
  if (triggers.length === 0) {
    errors.push({
      nodeId: '',
      message: 'Workflow must start with a trigger',
    });
  }

  // Validate each node's configuration
  nodes.forEach((node) => {
    const nodeErrors = validateNodeConfig(node);
    errors.push(...nodeErrors.map((message) => ({ nodeId: node.id, message })));
  });

  // Check for disconnected nodes
  const connectedNodeIds = new Set(edges.flatMap((edge) => [edge.source, edge.target]));
  nodes.forEach((node) => {
    if (!connectedNodeIds.has(node.id) && node.data.type !== 'trigger') {
      errors.push({
        nodeId: node.id,
        message: 'Node is not connected to the workflow',
      });
    }
  });

  return {
    isValid: errors.length === 0,
    errors,
  };
}

function validateNodeConfig(node: WorkflowNode): string[] {
  const errors: string[] = [];
  const { config } = node.data;

  // Check for required API key
  if (!config.apiKey) {
    errors.push('API key is required');
  }

  // Validate required parameters based on node type
  if (node.data.type === 'action') {
    if (!config.parameters) {
      errors.push('Action parameters are required');
    }
  }

  return errors;
}