import React from 'react';
import { BarChart, Activity, TrendingUp } from 'lucide-react';

export function Analytics() {
  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold text-white">Analytics</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <StatCard
          icon={<BarChart className="w-6 h-6 text-purple-400" />}
          title="Total API Calls"
          value="23,456"
          change="+12.3%"
          isPositive={true}
        />
        <StatCard
          icon={<Activity className="w-6 h-6 text-purple-400" />}
          title="Success Rate"
          value="99.9%"
          change="+0.2%"
          isPositive={true}
        />
        <StatCard
          icon={<TrendingUp className="w-6 h-6 text-purple-400" />}
          title="Response Time"
          value="45ms"
          change="-5ms"
          isPositive={true}
        />
      </div>

      <div className="bg-gray-800 rounded-xl p-6">
        <h2 className="text-xl font-semibold text-white mb-4">API Usage Over Time</h2>
        <div className="h-64 flex items-center justify-center">
          <p className="text-gray-400">Chart visualization will be implemented here</p>
        </div>
      </div>
    </div>
  );
}

function StatCard({ 
  icon, 
  title, 
  value, 
  change, 
  isPositive 
}: { 
  icon: React.ReactNode;
  title: string;
  value: string;
  change: string;
  isPositive: boolean;
}) {
  return (
    <div className="bg-gray-800 rounded-xl p-6">
      <div className="flex items-center justify-between mb-4">
        {icon}
        <span className={`text-sm ${isPositive ? 'text-green-400' : 'text-red-400'}`}>
          {change}
        </span>
      </div>
      <h3 className="text-gray-400 text-sm mb-1">{title}</h3>
      <p className="text-2xl font-bold text-white">{value}</p>
    </div>
  );
}