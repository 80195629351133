import { loadStripe } from '@stripe/stripe-js';

if (!import.meta.env.VITE_STRIPE_PUBLIC_KEY) {
  throw new Error('Missing Stripe public key');
}

export const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

export const STRIPE_PAYMENT_LINKS = {
  developer: 'https://buy.stripe.com/3csbLhayIfxNdS8dQT',
  growth: 'https://buy.stripe.com/fZeeXtcGQ99pg0g8ww',
  business: 'https://buy.stripe.com/3cs4iPeOYfxN6pG004',
  enterprise: 'mailto:enterprise@valiantapi.com'
} as const;

export type StripePlanId = keyof typeof STRIPE_PAYMENT_LINKS;