import { v4 as uuidv4 } from 'uuid';
import { generateSecureRandomString } from './crypto';

const KEY_PREFIX = {
  production: 'vlt_live',
  test: 'vlt_test'
} as const;

export function generateApiKey(type: 'production' | 'test'): string {
  const prefix = KEY_PREFIX[type];
  const timestamp = Date.now().toString(36);
  const randomPart = generateSecureRandomString(16);
  const uuid = uuidv4().replace(/-/g, '');
  
  return `${prefix}_${timestamp}${randomPart}${uuid}`;
}

export function formatApiKey(key: string): string {
  if (!key) return '';
  return key.replace(/(.{4})/g, '$1 ').trim();
}

export function validateApiKey(key: string): boolean {
  if (!key) return false;
  
  const [prefix] = key.split('_');
  const validPrefixes = Object.values(KEY_PREFIX);
  
  if (!validPrefixes.includes(prefix as any)) {
    return false;
  }
  
  const cleanKey = key.replace(/\s/g, '');
  const pattern = /^(vlt_live|vlt_test)_[a-zA-Z0-9]{64,}$/;
  
  return pattern.test(cleanKey);
}

export function maskApiKey(key: string): string {
  if (!key) return '';
  const [prefix, rest] = key.split('_');
  if (!rest) return '••••••••';
  return `${prefix}_${'•'.repeat(16)}${rest.slice(-4)}`;
}